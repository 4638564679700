import { Route, Switch, Redirect } from "react-router-dom";
import React, { Component } from "react";
import "../assets/css/landing.css";
import FooterLandingPage from "../components/footer-landing-page/FooterLandingPage";
import Navbar from "../components/navbar/Navbar";
import Webterminal from "./webterminal/index";
import RegisterPage from "./register";
import LoginPage from "./login";
import ForgotPasswordForm from "./forgotpassword/ForgotPasswordForm";
import Creditcard from "./dashboard-content/fund/deposit/Creditcard";
import { connect } from "react-redux";
import { Guest } from "../route";
import styled from "styled-components";
import { Button, ConfigProvider } from "antd";
import { getAccountTypeList } from "../actions";
import { theme } from "antd/lib";

const locale = require("react-redux-i18n").I18n;

const BoxSupport = styled.div`
  display: ${(props) => props.Display};
  position: fixed;
  bottom: 10px;
  right: 9px;
  color: #fff;
  background: #039fff;
  padding: 12px;
  border-radius: 26px;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
  font-family: sans-serif;
  cursor: pointer;
`;

const BoxCookie = styled.div`
  position: fixed;
  bottom: 10px;
  left: 9px;
  color: #fff;
  background: rgb(0 0 0 / 85%);
  padding: 24px;
  border-radius: 4px;
  align-items: center;
  font-size: 15px;
  font-family: sans-serif;
  cursor: pointer;
  max-width: 350px;
  z-index: 20;
`;

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible_info: false,
      checkbox: false,
    };
  }

  generateHexString(length) {
    var ret = "";
    while (ret.length < length) {
      ret += Math.random().toString(16).substring(2);
    }
    return ret.substring(0, length);
  }

  handleCheckCookie() {
    this.setState({
      visible_info: false,
    });
    localStorage.setItem("accept_cookie", this.generateHexString(58));
  }

  getCountry = async () => {
    try {
      const response = await fetch("https://portal.iux.com/api/country");
      if (!response.ok) {
        throw new Error("Failed to fetch country");
      }
      const data = await response.json();
      localStorage.setItem("country", data?.country);
    } catch (err) {
      console.error("Error:", err);
    } finally {
      console.log("");
    }
  };

  componentDidMount() {
    this.getCountry();
    this.props.getAccountTypeList();
    const isLang = this?.props?.match?.params?.lang;
    if (localStorage.getItem("locale") !== this?.props?.match?.params?.lang) {
      if (
        isLang === "en" ||
        isLang === "ms" ||
        isLang === "id" ||
        isLang === "th" ||
        isLang === "zh" ||
        isLang === "vi" ||
        isLang === "ko" ||
        isLang === "ja"
      ) {
        localStorage.setItem("locale", this?.props?.match?.params?.lang);
        window.location.reload();
      }
    }
    const item = localStorage.getItem("accept_cookie");
    if (!item) {
      this.setState({
        visible_info: true,
      });
    }
  }

  render() {
    const loc =
      this?.props?.match?.params?.lang || localStorage.getItem("locale");
    const pathname = window.location.pathname;
    let urlParams = new URLSearchParams(window.location.search);
    let mobile = urlParams.get("mobile");
    // console.log(pathname.startsWith(`/${loc}/login`));
    const renderNavbar = () => {
      if (
        pathname.startsWith(`/${loc}/webterminal`) ||
        pathname.startsWith(`/${loc}/login`) ||
        pathname.startsWith(`/${loc}/register`) ||
        pathname.startsWith(`/${loc}/forgot-password-form`)
      ) {
        return;
      } else {
        return <Navbar />;
      }
    };
    return (
      <div id="app" className="h-100">
        {/* <ClearLocalStorageOnBeforeUnload /> */}
        {/* <SocialButtonLandingPage /> */}
        <ConfigProvider
          theme={{
            algorithm: theme.darkAlgorithm,
            hashed: false,
            token: {
              colorPrimary: "#169376",
              fontFamily: "Inter",
              colorBgContainer: "rgb(36, 36, 36)",
              colorItemBgHover: "transparent",
            },
          }}
        >
          <Route path="/:lang/card" component={Creditcard} />
          <section id="navbar">
            {renderNavbar()}
            {/* <Navbar /> */}
          </section>
          <Switch>
            {/* <Route
              path="/:lang/maintenance"
              render={() => {
                return (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      height: "100vh",
                      background:
                        'url("/assets/images/covers/background-maintance.jpg")',
                      justifyContent: "center",
                    }}
                  >
                    <Result
                      style={{ alignSelf: "center" }}
                      icon={<SettingOutlined spin style={{ color: "white" }} />}
                      title={
                        <p style={{ color: "white", textAlign: "center" }}>
                          Emergency maintenance, Sorry for the inconvenience.!
                          <br></br>
                        </p>
                      }
                    />
                  </div>
                );
              }}
            />
            <Route
              path="/:lang/webterminal/version4"
              render={() => <Webterminal current="webterminal" version={4} />}
            />
   
            <Route
              path="/:lang/platforms/mt4"
              render={() => <PlatformPage current="platform" />}
            />
            <Route
              path="/:lang/platforms/mt5"
              render={() => <Platform5Page current="platform5" />}
            />
            <Route
              path="/:lang/market"
              render={() => <MarketPage current="market" />}
            />
            <Route
              path="/:lang/help"
              render={() => <HelpCenterPage current="help" />}
            />
            <Route
              path="/:lang/accounts"
              render={() => <AccountOverview current="accounts" />}
            />
            <Route
              path="/:lang/spread/symbol/analyze/:id"
              render={() => <Analyzesymbol current="analyze" />}
            />
            <Route
              path="/:lang/spread/marketresearch"
              render={() => <MarketResearch current="research" />}
            />
            <Route
              path="/:lang/instrument"
              render={() => <Symbol current="symbol" />}
            />
            <Route
              path="/:lang/swap"
              render={() => <Swap current="symbol" />}
            />
            <Route
              path="/:lang/payment-option"
              render={() => <PaymentOptionPage current="payment" />}
            />{" "}
            <Route
              path="/:lang/partner"
              render={() => <PartnerPage current="partner" />}
            />
            <Route path="/:lang/promotion" render={() => <StartBonusPage />} />
            <Route path="/:lang/lotback" render={() => <LotBackPage />} />
            <Route path="/:lang/deposit" render={() => <DepositPage />} />
            <Route path="/:lang/about-us" render={() => <AbountPage />} /> */}
            <Route
              path="/:lang/webterminal/version5"
              render={() => <Webterminal current="webterminal" version={5} />}
            />
            <Guest path="/:lang/register" component={RegisterPage} />
            <Guest path="/:lang/login" component={LoginPage} />
            <Route
              path="/:lang/forgot-password-form"
              component={ForgotPasswordForm}
            />
            <Redirect to={`/${loc}/login`} />
            {/* <Route path="/:lang/formpartner" component={FormPartner} />
            
            <Route path="/:lang/" component={MainPage} /> */}
            <FooterLandingPage locale={locale} />
          </Switch>

          <div className="postion-relative">
            {this.state.visible_info &&
              window.location.search !== "?hidenav=hide" && (
                <BoxCookie>
                  {/* <img src={process.env.PUBLIC_URL + '/assets/images/cookie.png'} alt="..." /> */}
                  <div className="">{locale.t("cookie_landing_page")}</div>
                  <div className="mt-3 w-100">
                    <Button
                      className="w-100"
                      onClick={() => this.handleCheckCookie()}
                    >
                      Accept all cookies
                    </Button>
                  </div>
                </BoxCookie>
              )}
            {/* {!mobile && (
            <BoxSupport
              onClick={() =>
                (window.location.href = `/${localStorage.getItem(
                  "locale"
                )}/help`)
              }
              Display={
                window.location.pathname === `/${loc}/help` ||
                window.location.pathname === `/${loc}/help/calculators` ||
                window.location.pathname === `/${loc}/help/economic-calendar` ||
                window.location.pathname === `/${loc}`
                  ? "none"
                  : "flex"
              }
            >
              <MessageOutlined />
              <div className="ml-2">24/7 Support</div>
            </BoxSupport>
          )} */}
          </div>
        </ConfigProvider>
      </div>
    );
  }
}

const mapStateToProps = ({ i18n }) => ({
  locale: i18n.locale,
});

export default connect(mapStateToProps, { getAccountTypeList })(LandingPage);
