import React from "react";
import { Link } from "react-router-dom";
import { Divider } from "antd";
import {
  styleFontTop,
  styleFontCurrent,
  styleHeader,
  styleBottom,
  styleSpread,
  styleBuy,
  styleSell,
  colorBit,
  colorAsk,
  styleBackground,
  TextGray,
  styleBitAsk,
  styleFontCurrentInt,
  TextSpead,
  styleClose,
  styleSpreadDashboard,
  styleBuyDashboard,
  styleSellDashboard,
  styleCloseDashboard,
} from "./style";
import { GlobalOutlined } from "@ant-design/icons";
const AllSymbol = ({ data, islayout }) => {
  const formatPrice = (price, digits) => {
    const fixedPrice = price.toFixed(digits);
    const lastDigits =
      digits <= 2
        ? fixedPrice.toString().substr(-2)
        : fixedPrice.toString().substr(-3);

    const mainPart = fixedPrice.replace(lastDigits, "");

    return {
      mainPart,
      firstTwoDigits: lastDigits.toString().substr(0, 2),
      lastDigit: digits > 2 ? lastDigits.toString().substr(2, 3) : null,
    };
  };

  const PriceDisplay = ({
    mainPart,
    firstTwoDigits,
    lastDigit,
    colorStyle,
  }) => (
    <div
      className="d-flex align-items-baseline justify-center"
      style={colorStyle}
    >
      <div style={styleFontTop}>{mainPart}</div>
      <div className="d-flex">
        <span style={styleFontCurrent}>{firstTwoDigits}</span>
        <div style={styleFontCurrentInt}>{lastDigit}</div>
      </div>
    </div>
  );

  const bidPrice = (data) => {
    const { mainPart, firstTwoDigits, lastDigit } = formatPrice(
      data.Bid,
      data.Digits
    );
    return (
      <PriceDisplay
        mainPart={mainPart}
        firstTwoDigits={firstTwoDigits}
        lastDigit={lastDigit}
        colorStyle={colorBit}
      />
    );
  };

  const askPrice = (data) => {
    const { mainPart, firstTwoDigits, lastDigit } = formatPrice(
      data.Ask,
      data.Digits
    );
    return (
      <PriceDisplay
        mainPart={mainPart}
        firstTwoDigits={firstTwoDigits}
        lastDigit={lastDigit}
        colorStyle={colorAsk}
      />
    );
  };
  const spreadPrice = (data) => {
    let spread = data.Ask - data.Bid;
    let a = Math.pow(10, data.Digits - 1);
    let final = spread * a;
    return (
      <div className="ml-1 text-right" style={{ width: 32 }}>
        {final.toFixed(1)}
      </div>
    );
  };
  const addDefaultSrc = (ev) => {
    ev.target.src = `/assets/images/icons/${
      islayout === "dashboard" ? "globe-black" : "globe"
    }.svg`;
  };
  return (
    <div className="row">
      {data?.map((item, index) => (
        <div
          className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3"
          key={item.Symbol + index}
        >
          <Link
            className={islayout === "dashboard" ? "text-black" : "text-white"}
            to={`/${localStorage.getItem("locale")}${
              islayout === "dashboard" ? "/wallet" : ""
            }/spread/symbol/analyze/${item.Display}`}
          >
            <div
              className={`w-100 ${
                islayout === "dashboard"
                  ? "bg-spreads-box-dashboard"
                  : "bg-spreads-box"
              } border-round-16 mb-2 p-0`}
            >
              <div className="p-3 p-lg-2 p-md-2 box-market-landingpage">
                <div
                  className="d-flex justify-content-between p-2"
                  // style={styleHeader}
                >
                  <div className="d-flex align-items-center">
                    <img
                      onError={addDefaultSrc}
                      src={`${
                        process.env.REACT_APP_SYMBOL
                      }symbol/${item?.Symbol.toUpperCase()}.png`}
                      width={30}
                      alt={item.s}
                      style={{ marginRight: 8 }}
                      className="rounded-circle"
                    />
                    &nbsp;
                    {item?.Symbol}
                  </div>
                </div>

                <div
                  className="d-flex justify-content-between pl-3 pr-3"
                  // style={styleBackground}
                >
                  <div className="w-100">
                    <div style={styleBitAsk}>Bid</div>
                    {bidPrice(item)}
                  </div>

                  <div className="w-100">
                    <div style={styleBitAsk} className="ml-2">
                      Ask
                    </div>
                    {askPrice(item)}
                  </div>
                </div>

                <div
                  className="d-flex justify-content-between"
                  // style={styleBottom}
                >
                  <div className="w-100">
                    {islayout === "dashboard" ? (
                      <div
                        className=" d-flex justify-content-between mr-1 btn-outline-iux"
                        style={styleSpreadDashboard}
                      >
                        Spread
                        {spreadPrice(item)}
                      </div>
                    ) : (
                      <div
                        className="text-white d-flex justify-content-between mr-1"
                        style={styleSpread}
                      >
                        <div className="text-gray" style={TextGray}>
                          Spread
                        </div>
                        <div className="text-right" style={TextSpead}>
                          {spreadPrice(item)}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="w-100">
                    <div className="d-flex justify-content-between ml-1 ">
                      {item.dt !== 0 ? (
                        <>
                          <div className="" />
                          <Link to={`/${localStorage.getItem("locale")}/login`}>
                            {islayout === "dashboard" ? (
                              <button
                                style={styleBuyDashboard}
                                className="btn-primary-new-iux"
                              >
                                Buy
                              </button>
                            ) : (
                              <div style={styleBuy}>Buy</div>
                            )}
                          </Link>
                          <Link to={`/${localStorage.getItem("locale")}/login`}>
                            {islayout === "dashboard" ? (
                              <div
                                className="btn-outline-iux"
                                style={styleSellDashboard}
                              >
                                Sell
                              </div>
                            ) : (
                              <div style={styleSell}>Sell</div>
                            )}
                          </Link>
                        </>
                      ) : (
                        <>
                          {islayout === "dashboard" ? (
                            <div
                              className="btn-outline-iux"
                              style={styleCloseDashboard}
                            >
                              Market Close
                            </div>
                          ) : (
                            <div style={styleClose}>Market Close</div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default AllSymbol;
