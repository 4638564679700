import { useEffect, useState } from "react";

const useLocationStorage = () => {
  const [location, setLocation] = useState(localStorage.getItem("country"));

  useEffect(() => {
    if (!location) {
      const intervalId = setInterval(() => {
        const currentLocal = localStorage.getItem("country");
        if (currentLocal) {
          setLocation(currentLocal);
          clearInterval(intervalId);
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [location]);

  // Add function to update location
  const updateLocation = (newLocation) => {
    try {
      if (newLocation) {
        localStorage.setItem("country", newLocation);
      } else {
        localStorage.removeItem("country");
      }
      setLocation(newLocation);
    } catch (error) {
      console.error("Error updating location:", error);
    }
  };

  // Add cross-tab synchronization
  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === "country") {
        setLocation(e.newValue);
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  return [location, updateLocation];
};

export default useLocationStorage;
