import React from "react";
const ImportantZa = (props) => {
  return (
    <div className="text-center important-za-component-style">
      <span className="text-white">
        Important Note: By completing and submitting this form you acknowledge
        and accept that you are registering an account with IUX Markets (MU)
        Ltd., which is authorized and regulated by the Financial Services
        Commission (FSC) in Mauritius with investment dealer license number
        GB22200605. IUX Markets ZA (Pty) Ltd. acts solely as an intermediary in
        terms of the FAIS Act, rendering only an intermediary service (i.e., no
        market making is conducted by IUX Markets ZA (Pty) Ltd.) in relation to
        derivative products (CFDs) offered by IUX Markets (MU) Ltd. (i.e., the
        Product Supplier). IUX Markets (MU) Ltd. falls outside the regulatory
        framework of South Africa and FSCA’s oversight and as such, FSC’s laws
        and regulations will apply in relation to your account.
      </span>
    </div>
  );
};

export default ImportantZa;
