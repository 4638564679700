export default {
  th: {
    something: "มีบางอย่างผิดพลาด",
    tryagain: "กรุณาลองอีกครั้ง",
    documentType: {
      ID: "บัตรประจำตัวประชาชน",
      DL: "ใบขับขี่",
      UID: "หมายเลขประจำตัวที่ไม่ซ้ำ (Aadhaar)",
      PASS: "หนังสือเดินทาง",
      RD: "บัตรประจำตัวผู้พำนัก",
      PCN: "บัตรประจำตัวประชาชนฟิลิปปินส์ (PhilID)",
      CRN: "บัตรประจำตัวเอนกประสงค์",
      validation_ID_th:
          "กรุณากรอกหมายเลขประจำตัวของคุณระหว่าง 10 ถึง 14 ตัวอักษร",
      validation_ID_my:
          "กรุณากรอกหมายเลข MyKad ของคุณระหว่าง 10 ถึง 14 ตัวอักษร",
      validation_ID_la: "ตัวเลข ความยาวระหว่าง 9 ถึง 14 ตัวอักษร",
      validation_ID_ng: "ตัวเลข ความยาวระหว่าง 9 ถึง 14 ตัวอักษร",
      validation_ID_gh: "ตัวเลข ความยาวระหว่าง 9 ถึง 17 ตัวอักษร",
      validation_ID_za: "ตัวเลข ความยาวระหว่าง 9 ถึง 14 ตัวอักษร",
      validation_ID_id: "กรุณากรอกหมายเลข NIK ของคุณระหว่าง 14 ถึง 18 ตัวอักษร",
      validation_NIK_id:
          "กรุณากรอกหมายเลข NIK ของคุณระหว่าง 14 ถึง 18 ตัวอักษร",
      validation_ID_vn:
          "โปรดป้อนหมายเลขประจำตัวของคุณระหว่าง 9 ถึง 13 อักขระ และรูปแบบบัตรเก่าไม่เป็นที่ยอมรับ",
      validation_ID_in: "ความยาวระหว่าง 8 ถึง 12 ตัวอักษร",
      validation_ID_jp: "ความยาวระหว่าง 9 ถึง 12 ตัวอักษร",
      validation_DL_my:
          "กรุณากรอกหมายเลขใบขับขี่ของคุณระหว่าง 10 ถึง 14 ตัวอักษร",
      validation_DL_ph: "กรุณากรอกอักขระ 11 ตัวของหมายเลขใบอนุญาตขับขี่ของคุณ",
      validation_DL_in: "ความยาวระหว่าง 15 ถึง 16 ตัวอักษร",
      validation_DL_jp: "ความยาวระหว่าง 9 ถึง 12 ตัวอักษร",
      validation_DL_ng: "ความยาวระหว่าง 8 ถึง 13 ตัวอักษร",
      validation_DL_gh: "ความยาวระหว่าง 8 ถึง 16 ตัวอักษร",
      validation_DL_za: "ความยาวระหว่าง 8 ถึง 13 ตัวอักษร",

      validation_UID_in: "ตัวเลขความยาว 12 ตัว",
      validation_PASS_ph:
          "กรุณากรอกตัวอักษร 9 ตัวของหมายเลขหนังสือเดินทางของคุณ",
      validation_PASS_la: "ตัวอักษรและตัวเลข หนึ่งตัวอักษรตามด้วยตัวเลข 7 หลัก",
      validation_PASS_ng: "ตัวอักษรและตัวเลข หนึ่งตัวอักษรตามด้วยตัวเลข 7 หลัก",
      validation_PASS_gh: "ตัวอักษรและตัวเลข หนึ่งตัวอักษรตามด้วยตัวเลข 7 หลัก",
      validation_PASS_za: "ตัวอักษรและตัวเลข หนึ่งตัวอักษรตามด้วยตัวเลข 7 หลัก",
      validation_PASS_jp: "ความยาวระหว่าง 9 ถึง 12 ตัวอักษร",
      validation_RD_jp: "ความยาวระหว่าง 9 ถึง 12 ตัวอักษร",
      validation_PCN_ph: "โปรดป้อนอักขระ 16 ตัวของหมายเลข PCN ของคุณ",
      validation_CRN_ph: "โปรดป้อนอักขระ 12 ตัวของหมายเลข CRN ของคุณ",
    },
    adminRejectTopic: "ขออภัยในความไม่สะดวก",
    adminRejectReason:
        "ขออภัยในความไม่สะดวกที่เกิดขึ้น ตรวจพบปัญหาในข้อมูลที่ให้ไว้ ทำให้ต้องทำการตรวจสอบอีกครั้ง กรุณาส่งใบสำคัญการยืนยันตัวตนของคุณเพื่อการตรวจสอบเพิ่มเติม",
    bookbankverifyTitle: "ตรวจสอบธนาคาร",
    kycTitle: "	ตรวจสอบตัวตน	",
    step1: "	ตรวจสอบเอกสาร	",
    step2: "	ข้อมูลพื้นฐาน	",
    step3: "	ตรวจสอบความสำเร็จ	",
    continue: "	ดำเนินการต่อ	",
    verifyidentity: "	ยืนยันตัวตนของคุณ	",
    acceptedfile:
        "	ประเภทไฟล์ที่ยอมรับได้คือ JPG, JPEG, PNG และขนาดไฟล์สูงสุดคือ 8 MB	",
    cameradevice: "	อุปกรณ์กล้องถ่ายรูป	",
    uploadfile: "	อัปโหลดไฟล์	",
    or: "	หรือ	",
    anotherdevice: "	ใช้อุปกรณ์อื่น	",
    anotherdeviceDetail:
        "	ข้อมูลส่วนบุคคลของคุณปลอดภัย ข้อมูลที่สกัดจากเอกสาร ID ของคุณอยู่ในIUX และไม่เคยถูกแชร์กับ บริษัท อื่น ๆ	",
    scancode: "	สแกนรหัสด้านล่างเพื่อใช้อุปกรณ์อื่น	",
    back: "	กลับ	",
    verificationFailed: "	การตรวจสอบล้มเหลว	",
    sorryverify: "	ขออภัย! เราไม่สามารถยืนยันตัวตนของคุณได้	",
    needsupport:
        "ต้องการความช่วยเหลือ? เราอยู่ที่นี่เพื่อช่วยคุณ 24/7 \n โปรดติดต่อเราผ่านไลฟ์แชท",
    uploadAgian: "อัปโหลดอีกครั้ง",
    tempblock: "	ความพยายามตรวจสอบถูกบล็อกชั่วคราว	",
    tempblockdetail:
        "	ความพยายามในการตรวจสอบตัวตนได้รับการบล็อกชั่วคราวเป็นระยะเวลา 5 นาที กรุณาลองอัปโหลดเอกสารอื่นในภายหลัง	",
    permanently: "	ความพยายามตรวจสอบถูกบล็อกอย่างถาวร	",
    permanentlydetail:
        "	ความพยายามในการตรวจสอบตัวตนของคุณได้รับการบล็อกอย่างถาวร กรุณาติดต่อ IUX Supports ผ่านไลฟ์แชทเพื่อขอความช่วยเหลือเพิ่มเติม	",
    countdown: "	การนับถอยหลัง	",
    loadingtitle: "	ในขณะที่การประมวลผลนี้โปรดเปิดหน้านี้	",
    autoverify: "การตรวจสอบอัตโนมัติ",
    tutorial: "บทแนะนำ",
    manualverify: "	การตรวจสอบตัวตนด้วยตนเอง	",

    trydemo: "บัญชีทดลอง",
    verifySuccess: "	ความสำเร็จในการตรวจสอบ	",
    verifySuccessdetial: "	ตัวตนของคุณได้รับการตรวจสอบแล้ว	",
    manualtitle: "	เอกสารประจำตัว	",
    manualsubtitle: "โปรดอัปโหลดรูปภาพ	",
    manualsubtitle2:
        "ภาพถ่ายควรสดใสและชัดเจนและเอกสารทั้งหมดของคุณจะต้องมองเห็นทุกมุมของเอกสาร",
    fontcard: "	ด้านหน้า	",
    backcard: "	ด้านหลัง	",
    manualselfie: "	อัปโหลดเซลฟี่ด้วยหลักฐาน	",
    manualselfiedetail:
        "	โปรดอัปโหลดเซลฟี่ด้วยเอกสาร Aadhaar ของคุณและเว็บไซต์ IUX ที่เปิดในอินเทอร์เน็ตเบราว์เซอร์ในรูปภาพเดียวกัน ตรวจสอบให้แน่ใจว่าทั้งคู่มองเห็นได้ชัดเจนและใบหน้าของคุณไม่ครอบคลุม	",
    otpverifytitle: "	การตรวจสอบ OTP	",
    invalidphone: "	โทรศัพท์ไม่ถูกต้องโปรดแก้ไขหมายเลขโทรศัพท์ของคุณ	",
    requestotp: "	ขอ OTP	",
    enterotp: "	ป้อนรหัสจาก SMS ที่เราส่งไป	",
    codeexpiresin: "	รหัสหมดอายุใน	",
    seconds: "	ไม่กี่วินาที	",
    codenotmatch: "	รหัสไม่ตรงกัน	",
    bookbankuploadtitle:
        "	กรุณาถ่ายรูปสมุดบัญชีธนาคารของคุณและตรวจสอบให้แน่ใจว่าเอกสารของคุณตรงตามข้อกำหนดต่อไปนี้	",
    bookbankuploaddetail1: "	คุณภาพดีสดใสและชัดเจน	",
    bookbankuploaddetail2: "	ประเภทไฟล์ JPG, JPEG, PNG, PDF	",
    bookbankuploaddetail3: "	ทุกมุมของเอกสารของคุณจะต้องปรากฏให้เห็น	",
    bookbankfilesize: "	ขนาดไฟล์ต้องไม่เกิน 8MB	",
    didotp: "ไม่ได้รับ OTP?",
    error_verify_type_file: "ขออภัยประเภทไฟล์ไม่รองรับ",
    error_verify_kyc: "ไม่ประสบความสำเร็จเราไม่สามารถแยกข้อมูลออกจากภาพได้",
    inprogress: "กำลังดำเนินการ",
    sendverifysuccess:
        "ส่งสำเร็จ! โดยทั่วไปใช้เวลาเพียง 3-15 นาทีเพื่อให้เราทำการตรวจสอบที่จำเป็นและยืนยันเอกสารของคุณให้เสร็จสมบูรณ์",
    kyc_scanning: "	กำลังสแกน ...	",
    kyc_detected: "	การตรวจจับประสบความสำเร็จ	",
    kyc_angle: "	เปลี่ยนมุม	",
    kyc_fail: "การตรวจจับล้มเหลว",
    kyc_move_far: "ขยับเอกสารให้ไกลออกไป",
    kyc_move: "	ขยับเอกสารให้ใกล้ชิดยิ่งขึ้น	",
  },
  lo: {
    something: "ມີບັນຫາອັນໃດອັນນຶ່ງເກີດຂື້ນ",
    tryagain: "ກະລຸນາລອງອີກຄັ້ງ",
    documentType: {
      ID: "ບັດ​ປະ​ຈໍາ​ຕົວ",
      DL: "ໃບ​ຂັບ​ຂີ່",
      UID: "ໝາຍເລກປະຈຳຕົວທີ່ບໍ່ຊໍ້າກັນ (Aadhaar)",
      PASS: "ໜັງສືຜ່ານແດນ",
      RD: "ບັດທີ່ຢູ່ອາໄສ",
      PCN: "ບັດປະຈຳຕົວແຫ່ງຊາດຟີລິບປິນ (PhilID)",
      CRN: "Unified Multi-Purpose ID",
      validation_ID_th:
          "ກະລຸນາໃສ່ລະຫວ່າງ 10 ຫາ 14 ຕົວອັກສອນຂອງໝາຍເລກປະຈໍາຕົວຂອງທ່ານ.",
      validation_ID_my:
          "ກະລຸນາໃສ່ລະຫວ່າງ 10 ຫາ 14 ຕົວອັກສອນຂອງຕົວເລກ MyKad ຂອງທ່ານ.",
      validation_ID_la: "ຕົວເລກ. ຄວາມຍາວລະຫວ່າງ 9 ຫາ 14",
      validation_ID_ng: "ຕົວເລກ. ຄວາມຍາວລະຫວ່າງ 9 ຫາ 14",
      validation_ID_gh: "ຕົວເລກ. ຄວາມຍາວລະຫວ່າງ 9 ຫາ 17",
      validation_ID_za: "ຕົວເລກ. ຄວາມຍາວລະຫວ່າງ 9 ຫາ 14",
      validation_ID_id:
          "ກະລຸນາໃສ່ຕົວເລກ NIK ຂອງທ່ານລະຫວ່າງ 14 ຫາ 18 ຕົວອັກສອນ.",
      validation_NIK_id:
          "ກະລຸນາໃສ່ຕົວເລກ NIK ຂອງທ່ານລະຫວ່າງ 14 ຫາ 18 ຕົວອັກສອນ.",
      validation_ID_vn:
          "ກະລຸນາໃສ່ລະຫວ່າງ 9 ຫາ 13 ຕົວອັກສອນຂອງໝາຍເລກບັດປະຈຳຕົວຂອງເຈົ້າ ແລະຮູບແບບບັດເກົ່າບໍ່ສາມາດຍອມຮັບໄດ້.",
      validation_ID_in: "ຄວາມຍາວລະຫວ່າງ 8 ຫາ 12",
      validation_ID_jp: "ຄວາມຍາວລວມລະຫວ່າງ 9 ຫາ 12",
      validation_DL_my:
          "ກະລຸນາໃສ່ລະຫວ່າງ 10 ຫາ 14 ຕົວອັກສອນຂອງໝາຍເລກໃບຂັບຂີ່ຂອງເຈົ້າ.",
      validation_DL_ph: "ກະລຸນາໃສ່ 11 ຕົວອັກສອນຂອງໝາຍເລກໃບຂັບຂີ່ຂອງເຈົ້າ.",
      validation_DL_in: "ຄວາມຍາວລະຫວ່າງ 15 ຫາ 16 ຕົວອັກສອນ.",
      validation_DL_jp: "ຄວາມຍາວລວມລະຫວ່າງ 9 ຫາ 12",
      validation_DL_ng: "ຄວາມຍາວລວມລະຫວ່າງ 8 ຫາ 13",
      validation_DL_gh: "ຄວາມຍາວລວມລະຫວ່າງ 8 ຫາ 16",
      validation_DL_za: "ຄວາມຍາວລວມລະຫວ່າງ 8 ຫາ 13",
      validation_UID_in: "ຕົວເລກ. ຄວາມຍາວ 12",
      validation_PASS_ph: "ກະລຸນາໃສ່ 9 ຕົວອັກສອນຂອງເລກ ໜັງສືຜ່ານແດນ ຂອງທ່ານ.",
      validation_PASS_la: "ຕົວລະຄອນໜຶ່ງຕາມດ້ວຍ 7 ຕົວເລກ",
      validation_PASS_jp: "ຄວາມຍາວລວມລະຫວ່າງ 9 ຫາ 12",
      validation_PASS_ng: "ຄວາມຍາວລວມລະຫວ່າງ 9 ຫາ 12",
      validation_PASS_gh: "ຄວາມຍາວລວມລະຫວ່າງ 9 ຫາ 12",
      validation_PASS_za: "ຄວາມຍາວລວມລະຫວ່າງ 9 ຫາ 12",
      validation_RD_jp: "ຄວາມຍາວລວມລະຫວ່າງ 9 ຫາ 12",
      validation_PCN_ph: "ກະລຸນາໃສ່ 16 ຕົວອັກສອນຂອງໝາຍເລກ PCN ຂອງທ່ານ.",
      validation_CRN_ph: "ກະລຸນາໃສ່ 12 ຕົວອັກສອນຂອງໝາຍເລກ CRN ຂອງທ່ານ.",
    },
    adminRejectTopic: "ຂໍອະໄພທີ່ກ່ຽວກັບບົດຄວາມ",
    adminRejectReason:
        "ຂໍອະໄພທີ່ກ່ຽວກັບບົດຄວາມທີ່ໃຊ້ງານ ຊອບທັມເພື່ອກວດສອບຄືນ ກະລຸນາສົ່ງປະເພດການຕັ້ງຄ່າຄືນສໍາລັບການກວດສອບເພື່ອປັບປຸງເປັນສະຖານທີ່ເພີ່ມເພີ່ມ",
    bookbankverifyTitle: "ຫຼັກຖານສະແດງການຢັ້ງຢືນທະນາຄານ",
    kycTitle: "	ຫຼັກຖານສະແດງການຢັ້ງຢືນຕົວຕົນ	",
    step1: "	ຢືນຢັນເອກະສານ	",
    step2: "	ຂໍ້ມູນພື້ນຖານ	",
    step3: "	ກວດສອບຄວາມສໍາເລັດ	",
    continue: "	ດໍາເນີນຕໍ່ໄປ	",
    verifyidentity: "	ຢືນຢັນຕົວຕົນຂອງທ່ານ	",
    acceptedfile:
        "	ປະເພດໄຟລ໌ທີ່ຍອມຮັບແມ່ນ JPG, JPEG, PNG, PNG ແລະຂະຫນາດໄຟລ໌ສູງສຸດແມ່ນ 8 MB.	",
    cameradevice: "	ອຸປະກອນກ້ອງ	",
    uploadfile: "	ອັບໂຫລດເອກະສານ	",
    or: "	ຫຼື	",
    anotherdevice: "	ໃຊ້ອຸປະກອນອື່ນ	",
    anotherdeviceDetail:
        "	ຂໍ້ມູນສ່ວນຕົວຂອງທ່ານແມ່ນປອດໄພ. ຂໍ້ມູນທີ່ສະກັດຈາກເອກະສານປະຈໍາຕົວຂອງທ່ານຢູ່ພາຍໃນຕະຫຼາດ iux ແລະບໍ່ເຄີຍແບ່ງປັນກັບບໍລິສັດອື່ນ.	",
    scancode: "	ສະແກນລະຫັດຂ້າງລຸ່ມນີ້ເພື່ອໃຊ້ອຸປະກອນອື່ນ.	",
    back: "	ກັບຄືນໄປບ່ອນ	",
    verificationFailed: "	ການຢັ້ງຢືນລົ້ມເຫລວ	",
    sorryverify: "	ຂໍ​ໂທດ! ພວກເຮົາບໍ່ສາມາດກວດສອບຕົວຕົນຂອງທ່ານໄດ້	",
    needsupport:
        "	ຕ້ອງການຄວາມຊ່ວຍເຫຼືອບໍ? ພວກເຮົາຢູ່ທີ່ນີ້ເພື່ອຊ່ວຍທ່ານ 24/7.  npletase ຕິດຕໍ່ພວກເຮົາໂດຍຜ່ານການສົນທະນາສົດໆ.	",
    uploadAgian: "ອັບໂຫລດອີກເທື່ອຫນຶ່ງ",
    tempblock: "	ຄວາມພະຍາຍາມກວດສອບໄດ້ຖືກປິດກັ້ນຊົ່ວຄາວ	",
    tempblockdetail:
        "	ຄວາມພະຍາຍາມກວດສອບຕົວຕົນໄດ້ຖືກປິດກັ້ນຊົ່ວຄາວໃນໄລຍະເວລາ 5 ນາທີ. ກະລຸນາລອງອັບໂຫລດເອກະສານອື່ນໃນເວລາຕໍ່ມາ.	",
    permanently: "	ຄວາມພະຍາຍາມກວດສອບໄດ້ຖືກສະກັດຢ່າງຖາວອນ	",
    permanentlydetail:
        "	ຄວາມພະຍາຍາມຢັ້ງຢືນຕົວຕົນຕົວຕົນຂອງທ່ານໄດ້ຖືກສະກັດກັ້ນຢ່າງຖາວອນ. ກະລຸນາຕິດຕໍ່ IUX  ສະຫນັບສະຫນູນໂດຍຜ່ານການສົນທະນາສົດໆສໍາລັບການຊ່ວຍເຫຼືອເພີ່ມເຕີມ.	",
    countdown: "	ນັບຖອຍຫລັງ	",
    loadingtitle: "	ໃນຂະນະທີ່ການປະມວນຜົນນີ້, ກະລຸນາຮັກສາຫນ້ານີ້ໃຫ້ເປີດ	",

    manualverify: "	ການກວດສອບຕົວຕົນດ້ວຍຕົນເອງ	",
    autoverify: "ກວດສອບອັດຕະໂນມັດ",
    tutorial: "ວິທີການສະຫຼຸບ",
    trydemo: "	ບັນຊີຕົວຢ່າງ	",
    verifySuccess: "	ຄວາມສໍາເລັດການຢັ້ງຢືນ	",
    verifySuccessdetial: "	ຕົວຕົນຂອງທ່ານໄດ້ຮັບການຢັ້ງຢືນແລ້ວ.	",
    manualtitle: "	ເອກະສານປະຈໍາຕົວ	",
    manualsubtitle: "	ກະລຸນາອັບໂຫລດຮູບ",
    manualsubtitle2:
        " ຮູບພາບດັ່ງກ່າວຄວນຈະສົດໃສແລະຊັດເຈນ, ແລະທັງຫມົດເຊື່ອມຕໍ່ຂອງເອກະສານຂອງທ່ານຕ້ອງເບິ່ງເຫັນໄດ້.	",
    fontcard: "	ແນວຫນ້າ	",
    backcard: "	ກັບຄືນໄປບ່ອນ	",
    manualselfie: "	ອັບໂຫລດ selfie ກັບຫຼັກຖານ	",
    manualselfiedetail:
        "	ກະລຸນາອັບໂຫລດເອກະພາບຂອງ Selfie ກັບເອກະສານ Aadhaar ຂອງທ່ານແລະເວັບໄຊທ໌ Aux Markets ໄດ້ເປີດຢູ່ໃນອິນເຕີເນັດ browser ໃນຮູບດຽວກັນ. ຮັບປະກັນວ່າທັງສອງແມ່ນເຫັນໄດ້ຊັດເຈນແລະໃບຫນ້າຂອງທ່ານບໍ່ໄດ້ຖືກປົກຄຸມ.	",
    otpverifytitle: "	ການຢັ້ງຢືນ OTP	",
    invalidphone: "	ໂທລະສັບບໍ່ຖືກຕ້ອງ, ດັດແກ້ເບີໂທລະສັບຂອງທ່ານ.	",
    requestotp: "	ຮ້ອງຂໍ OTP	",
    enterotp: "	ໃສ່ລະຫັດຈາກ SMS ທີ່ພວກເຮົາສົ່ງໄປ	",
    codeexpiresin: "	ລະຫັດຫມົດອາຍຸໃນ	",
    seconds: "	ວິນາທີ	",
    codenotmatch: "	ລະຫັດບໍ່ກົງກັນ.	",
    bookbankuploadtitle:
        "ກະລຸນາໄດ້ອອກປື້ມບັນຊີຂອງທ່ານແລະເສຍຄ່າເຄື່ອງມືນຂອງທ່ານກັບຄວາມຕອບທີ່ລະບຸ	",
    bookbankuploaddetail1: "	ຄຸນນະພາບດີ, ສົດໃສແລະຈະແຈ້ງ.	",
    bookbankuploaddetail2: "	ປະເພດເອກະສານ JPG, JPEG, PNG, PDF.	",
    bookbankuploaddetail3: "	ທຸກມຸມຂອງເອກະສານຂອງທ່ານຕ້ອງເບິ່ງເຫັນ.	",
    bookbankfilesize: "	ຂະຫນາດຂອງເອກະສານບໍ່ສາມາດເກີນ 8Mb.	",
    didotp: "ບໍ່ໄດ້ຮັບ OTP ບໍ?",
    error_verify_type_file: "ຂໍໂທດ, ປະເພດເອກະສານບໍ່ສາມາດຍອມຮັບໄດ້.",
    error_verify_kyc: "ບໍ່ສໍາເລັດຜົນ, ພວກເຮົາບໍ່ສາມາດສະກັດເອົາຂໍ້ມູນຈາກຮູບພາບ.",
    inprogress: "ມີຄວາມຄືບຫນ້າ",
    sendverifysuccess:
        "ຕ້ອງການຄວາມຊ່ວຍເຫຼືອບໍ? ພວກເຮົາຢູ່ທີ່ນີ້ເພື່ອຊ່ວຍທ່ານ 24/7 \n ກະລຸນາຕິດຕໍ່ຫາພວກເຮົາໂດຍຜ່ານການສົນທະນາທີ່ມີຊີວິດ.",
    kyc_scanning: "	ສະແກນ ...	",
    kyc_detected: "	ການຊອກຄົ້ນຫາຜົນປະສົບຜົນສໍາເລັດ	",
    kyc_angle: "	ປັບມຸມ	",
    kyc_fail: "ການຊອກຄົ້ນຫາລົ້ມເຫລວ",
    kyc_move_far: "ເອກະສານຍ້າຍອອກໄປໄກກວ່າ",
    kyc_move: "	C ເອກະສານຍ້າຍ C ເອກະສານ	",
  },
  en: {
    something: "Something went wrong",
    tryagain: "Please try again",
    documentType: {
      ID: "Identification Card",
      DL: "Driver's License",
      UID: "Unique Identification Number (Aadhaar)",
      PASS: "Passport",
      RD: "Residence Card",
      PCN: "Philippine national ID card (PhilID)",
      CRN: "Unified Multi-Purpose ID",
      validation_ID_th:
          "Please enter between 10 and 14 characters of your ID number.",
      validation_ID_my:
          "Please enter between 10 and 14 characters of your MyKad number.",
      validation_ID_la:
          "Please enter between 9 and 14 characters of your ID number.",
      validation_ID_ng:
          "Please enter between 9 and 14 characters of your ID number.",
      validation_ID_gh:
          "Please enter between 9 and 17 characters of your ID number.",
      validation_ID_za:
          "Please enter between 9 and 14 characters of your ID number.",
      validation_ID_id:
          "Please enter between 14 and 18 characters of your NIK number.",
      validation_NIK_id:
          "Please enter between 14 and 18 characters of your NIK number.",
      validation_ID_vn:
          "Please enter between 9 and 13 characters of your ID number and the old card format is not acceptable.",
      validation_ID_in: "Length between 8 and 12 inclusive",
      validation_ID_jp: "Length between 9 and 12 inclusive",
      validation_DL_my:
          "Please enter between 10 and 14 characters of your Driver's license number.",
      validation_DL_ph:
          "Please enter the 11 characters of your Driver's license number.",
      validation_DL_in: "Length between 15 and 16 inclusive",
      validation_DL_jp: "Length between 9 and 12 inclusive",
      validation_DL_ng: "Length between 8 and 13 inclusive",
      validation_DL_gh: "Length between 8 and 16 inclusive",
      validation_DL_za: "Length between 8 and 13 inclusive",
      validation_UID_in: "Numeric. Length 12",
      validation_PASS_ph:
          "Please enter the 9 characters of your Passport number.",
      validation_PASS_la: "Alphanumeric. One character followed by 7 digits",
      validation_PASS_jp: "Length between 9 and 12 inclusive",
      validation_PASS_ng: "Length between 9 and 12 inclusive",
      validation_PASS_gh: "Length between 9 and 12 inclusive",
      validation_PASS_za: "Length between 9 and 12 inclusive",
      validation_RD_jp: "Length between 9 and 12 inclusive",
      validation_PCN_ph: "Please enter the 16 characters of your PCN number.",
      validation_CRN_ph: "Please enter the 12 characters of your CRN number.",
    },

    adminRejectTopic: "We apologize for the inconvenience caused.",
    adminRejectReason:
        "We apologize for the inconvenience caused. An issue has been identified with the information provided, which requires reevaluation. Please resubmit your proof of identity for further review.",
    bookbankverifyTitle: "Proof of bank verification",
    kycTitle: "Proof of identity verification",
    step1: "Verify document",
    step2: "Base \ninformation",
    step3: "Verify success",
    continue: "Continue",
    verifyidentity: "Verify your identity",
    acceptedfile:
        "Accepted file types are jpg, jpeg, png and max file size is 8 MB.",
    cameradevice: "Camera device",
    uploadfile: "Upload file",
    or: "or",
    anotherdevice: "Use another device",
    anotherdeviceDetail:
        "Your personal information is secure. The data extracted from your ID document stays within IUX and never gets shared with other companies.",
    scancode: "Scan the code below to use another device.",
    back: "Back",
    verificationFailed: `Verification Failed`,
    sorryverify: "Sorry! We couldn’t verify your identity",
    needsupport: `Need assistance? We are here to help you 24/7.\n Please contact us via Live Chat.`,
    uploadAgian: `Upload Again`,
    tempblock: `Verification attempt was temporarily blocked`,
    tempblockdetail: `Identity verification attempt has been temporarily blocked for a duration of 5 minutes. Kindly try uploading another document at a later time.`,
    permanently: `Verification attempt was permanently blocked`,
    permanentlydetail: `Your identity verification attempt has been permanently blocked. Please contact IUX Support through Live Chat for further assistance.`,
    countdown: `Countdown`,
    loadingtitle: `While this processing, please keep this page open`,
    autoverify: "Automatic verification",
    tutorial: "Tutorial",
    manualverify: `Manual verification`,
    trydemo: "Try Demo",
    verifySuccess: `Verification Success`,
    verifySuccessdetial: ` Your identity has been verified.`,
    manualtitle: `Identity document`,
    manualsubtitle: `Please upload a photo of your  `,
    manualsubtitle2:
        "The photo should be bright and clear, and all conners of your document must be visible.",
    fontcard: "Front",
    backcard: "Back",
    manualselfie: `Upload the selfie with the proof`,
    manualselfiedetail: `Please upload a selfie with your Aadhaar document and the IUX website opened in the internet browser in the same photo. Ensure that both are clearly visible and that your face is not covered.`,
    otpverifytitle: `OTP Verification`,
    invalidphone: `Invalid phone, kindly edit your phone number.`,
    requestotp: `Request OTP`,
    enterotp: `Enter the code from the sms we sent to`,
    codeexpiresin: `Code expires in `,
    seconds: "seconds",
    codenotmatch: `The code doesn’t match.`,
    bookbankuploadtitle: `Please take a picture of your bank passbook and ensure that your documents meet the following requirements.
    `,
    bookbankuploaddetail1: `Good quality, bright and clear.`,
    bookbankuploaddetail2: `File type jpg, jpeg, png, pdf.`,
    bookbankuploaddetail3: `All corners of your document must be visible.`,
    bookbankfilesize: `File size cannot exceed 8mb.`,
    didotp: "Didn’t receive OTP?",
    error_verify_type_file: "Sorry, file type cannot be accepted.",
    error_verify_kyc:
        "Unsuccessful, we were unable to extract information from image.",
    inprogress: "In progress",
    sendverifysuccess:
        "Successfully submitted! It typically takes just 3-15 minutes for us to complete the necessary checks and confirm your document.",
    kyc_scanning: "	Scanning...	",
    kyc_detected: "	Detection successful	",
    kyc_angle: "	Adjust the angle	",
    kyc_fail: "Detection failed",
    kyc_move_far: "Move document farther",
    kyc_move: "	Move document closer	",
  },
  hi: {
    something: "कुछ गलत हो गया है",
    tryagain: "कृपया पुनः प्रयास करें",

    documentType: {
      ID: "पहचान पत्र",
      DL: "ड्राइवर का लाइसेंस",
      UID: "अद्वितीय पहचान संख्या (आधार)",
      PASS: "पासपोर्ट",
      RD: "निवास पत्र",
      PCN: "फिलीपींस राष्ट्रीय आईडी कार्ड (PhilID)",
      CRN: "एकीकृत बहु-उद्देश्यीय आईडी",
      validation_ID_th: "कृपया अपने आईडी नंबर के 10 से 14 अक्षर दर्ज करें।",
      validation_ID_my:
          "कृपया अपने MyKad नंबर के 10 से 14 अंकों के बीच दर्ज करें।",
      validation_ID_la:
          "कृपया अपने आईडी नंबर के 9 से 14 अंकों के बीच दर्ज करें।",
      validation_ID_ng:
          "कृपया अपने आईडी नंबर के 9 से 14 अंकों के बीच दर्ज करें।",
      validation_ID_gh:
          "कृपया अपने आईडी नंबर के 9 से 17 अंकों के बीच दर्ज करें।",
      validation_ID_za:
          "कृपया अपने आईडी नंबर के 9 से 14 अंकों के बीच दर्ज करें।",
      validation_ID_id: "कृपया अपने NIK नंबर के 14 से 18 अक्षर दर्ज करें।",
      validation_NIK_id: "कृपया अपने NIK नंबर के 14 से 18 अक्षर दर्ज करें।",
      validation_ID_vn:
          "कृपया अपने आईडी नंबर के 9 से 13 अक्षर दर्ज करें और पुराने कार्ड प्रारूप को स्वीकार्य नहीं है।",
      validation_ID_in: "8 से 12 अक्षरों की लंबाई",
      validation_ID_jp: "9 से 12 अक्षरों की लंबाई",
      validation_DL_my:
          "कृपया अपने ड्राइवर लाइसेंस नंबर के 10 से 14 अक्षर दर्ज करें।",
      validation_DL_ph:
          "कृपया अपने ड्राइवर लाइसेंस नंबर के 11 अक्षर दर्ज करें।",
      validation_DL_in: "15 से 16 अक्षरों की लंबाई",
      validation_DL_jp: "9 से 12 अक्षरों की लंबाई",
      validation_DL_ng: "8 से 13 अक्षरों की लंबाई",
      validation_DL_gh: "8 से 16 अक्षरों की लंबाई",
      validation_DL_za: "8 से 13 अक्षरों की लंबाई",
      validation_UID_in: "संख्यात्मक। लंबाई 12",
      validation_PASS_ph: "कृपया अपने पासपोर्ट नंबर के 9 अक्षर दर्ज करें।",
      validation_PASS_la: "अल्फ़ान्यूमेरिक। एक अक्षर के बाद 7 अंक",
      validation_PASS_jp: "9 से 12 अक्षरों की लंबाई",
      validation_PASS_ng: "9 से 12 अक्षरों की लंबाई",
      validation_PASS_gh: "9 से 12 अक्षरों की लंबाई",
      validation_PASS_za: "9 से 12 अक्षरों की लंबाई",
      validation_RD_jp: "9 से 12 अक्षरों की लंबाई",
      validation_PCN_ph: "कृपया अपने PCN नंबर के 16 अक्षर दर्ज करें।",
      validation_CRN_ph: "कृपया अपने CRN नंबर के 12 अक्षर दर्ज करें।",
    },
    adminRejectTopic: "की गई असुविधा के लिए हम खेद प्रकट करते हैं।",
    adminRejectReason:
        "की गई असुविधा के लिए हम खेद प्रकट करते हैं। प्रदान की गई जानकारी में एक समस्या की पहचान की गई है, जिसे पुनरार्वाचन की आवश्यकता है। कृपया और समीक्षा के लिए अपना प्रमाण भेजें।",
    bookbankverifyTitle: "बैंक सत्यापन का प्रमाण",
    kycTitle: "	पहचान सत्यापन का प्रमाण	",
    step1: "	दस्तावेज़ सत्यापित करें	",
    step2: "	आधार सूचना	",
    step3: "	सफलता को सत्यापित करें	",
    continue: "	जारी रखना	",
    verifyidentity: "	अपनी पहचान सत्यापित करो	",
    acceptedfile:
        "	स्वीकृत फ़ाइल प्रकार JPG, JPEG, PNG और अधिकतम फ़ाइल का आकार 8 mb है।	",
    cameradevice: "	कैमरा युक्ति	",
    uploadfile: "	फ़ाइल अपलोड करें	",
    or: "	या	",
    anotherdevice: "	किसी अन्य डिवाइस का उपयोग करें	",
    anotherdeviceDetail:
        "	आपकी व्यक्तिगत जानकारी सुरक्षित है। आपके आईडी दस्तावेज़ से निकाला गया डेटा IUX के भीतर रहता है और अन्य कंपनियों के साथ कभी भी साझा नहीं होता है।	",
    scancode:
        "	किसी अन्य डिवाइस का उपयोग करने के लिए नीचे दिए गए कोड को स्कैन करें।	",
    back: "	पीछे	",
    verificationFailed: "	सत्यापन में विफल रहा	",
    sorryverify: "	क्षमा मांगना! हम आपकी पहचान को सत्यापित नहीं कर सकते	",
    needsupport:
        "सहायता की जरूरत है? हम यहां आपकी मदद करने के लिए हैं 24/7 \n कृपया लाइव चैट के माध्यम से हमसे संपर्क करें।",
    uploadAgian: "	फिर से अपलोड करें	",
    tempblock: "	सत्यापन का प्रयास अस्थायी रूप से अवरुद्ध कर दिया गया था	",
    tempblockdetail:
        "	5 मिनट की अवधि के लिए पहचान सत्यापन प्रयास को अस्थायी रूप से अवरुद्ध कर दिया गया है। कृपया बाद में एक और दस्तावेज़ अपलोड करने का प्रयास करें।	",
    permanently: "	सत्यापन का प्रयास स्थायी रूप से अवरुद्ध किया गया था	",
    permanentlydetail:
        "	आपकी पहचान सत्यापन प्रयास को स्थायी रूप से अवरुद्ध कर दिया गया है। कृपया आगे की सहायता के लिए लाइव चैट के माध्यम से IUX मार्केट्स सपोर्ट से संपर्क करें।	",
    countdown: "	उलटी गिनती	",
    loadingtitle: "	इस प्रसंस्करण के दौरान, कृपया इस पृष्ठ को खुला रखें	",

    manualverify: "	मैनुअल पहचान सत्यापन	",
    autoverify: "स्वचालित सत्यापन",
    tutorial: "ट्यूटोरियल",
    trydemo: "	डेमो का प्रयास करें	",
    verifySuccess: "	सत्यापन सफलता	",
    verifySuccessdetial: "	आपकी पहचान सत्यापित की गई है।	",
    manualtitle: "	पहचान दस्तावेज़	",
    manualsubtitle: "	कृपया की एक तस्वीर अपलोड करें",
    manualsubtitle2:
        "फोटो उज्ज्वल और स्पष्ट होना चाहिए, और आपके दस्तावेज़ के सभी कॉनर दिखाई देना चाहिए।",
    fontcard: "	सामने	",
    backcard: "	पीछे	",
    manualselfie: "	सबूत के साथ सेल्फी अपलोड करें	",
    manualselfiedetail:
        "	कृपया अपने आधार दस्तावेज़ के साथ एक सेल्फी अपलोड करें और उसी फोटो में इंटरनेट ब्राउज़र में खोली गई IUX  वेबसाइट। सुनिश्चित करें कि दोनों स्पष्ट रूप से दिखाई दे रहे हैं और आपका चेहरा कवर नहीं है।	",
    otpverifytitle: "	ओटीपी सत्यापन	",
    invalidphone: "	अमान्य फोन, कृपया अपना फ़ोन नंबर संपादित करें।	",
    requestotp: "	अनुरोध ओटीपी	",
    enterotp: "	हमारे द्वारा भेजे गए एसएमएस से कोड दर्ज करें	",
    codeexpiresin: "	कोड समाप्त हो रहा है	",
    seconds: "	सेकंड	",
    codenotmatch: "	कोड मेल नहीं खाता है।	",
    bookbankuploadtitle:
        "कृपया अपने बैंक पासबुक की एक तस्वीर लें और सुनिश्चित करें कि आपके दस्तावेज निम्नलिखित आवश्यकताओं को पूरा करते हैं",
    bookbankuploaddetail1: "	अच्छी गुणवत्ता, उज्ज्वल और स्पष्ट।	",
    bookbankuploaddetail2: "	फ़ाइल प्रकार JPG, JPEG, PNG, PDF।	",
    bookbankuploaddetail3: "	आपके दस्तावेज़ के सभी कोनों को दिखाई देना चाहिए।	",
    bookbankfilesize: "	फ़ाइल का आकार 8MB से अधिक नहीं हो सकता है।	",
    didotp: "क्या ओटीपी प्राप्त नहीं हुआ?",
    error_verify_type_file:
        "क्षमा करें, फ़ाइल प्रकार को स्वीकार नहीं किया जा सकता है।",
    error_verify_kyc: "असफल, हम छवि से जानकारी निकालने में असमर्थ थे।",
    inprogress: "प्रगति पर है",
    sendverifysuccess:
        "सफलतापूर्वक प्रस्तुत किया गया! आमतौर पर हमें आवश्यक चेक पूरा करने और अपने दस्तावेज़ की पुष्टि करने में सिर्फ 3-15 मिनट लगते हैं।",
    kyc_scanning: "	स्कैनिंग ...	",
    kyc_detected: "	पता लगाने में सफल	",
    kyc_angle: "	कोण को समायोजित करें	",
    kyc_fail: "पता लगाने में विफल रहा",
    kyc_move_far: "दस्तावेज़ आगे बढ़ें",
    kyc_move: "	दस्तावेज़ करीब ले जाएं	",
  },
  ms: {
    something: "Sesuatu yang salah berlaku",
    tryagain: "Sila cuba lagi",

    documentType: {
      ID: "Kad Pengenalan",
      DL: "Lesen Memandu",
      UID: "Nombor Pengenalan Unik (Aadhaar)",
      PASS: "Pasport",
      RD: "Kad Penduduk",
      PCN: "Kad ID kebangsaan Filipina (PhilID)",
      CRN: "ID Serbaguna Terpadu",
      validation_ID_th:
          "Sila masukkan antara 10 hingga 14 aksara nombor ID anda.",
      validation_ID_my:
          "Sila masukkan antara 10 hingga 14 aksara nombor MyKad anda.",
      validation_ID_la:
          "Sila masukkan antara 9 hingga 14 aksara nombor ID anda.",
      validation_ID_ng:
          "Sila masukkan antara 9 hingga 14 aksara nombor ID anda.",
      validation_ID_gh:
          "Sila masukkan antara 9 hingga 17 aksara nombor ID anda.",
      validation_ID_za:
          "Sila masukkan antara 9 hingga 14 aksara nombor ID anda.",
      validation_ID_id:
          "Sila masukkan antara 14 hingga 18 aksara nombor NIK anda.",
      validation_NIK_id:
          "Sila masukkan antara 14 hingga 18 aksara nombor NIK anda.",
      validation_ID_vn:
          "Sila masukkan antara 9 hingga 13 aksara nombor ID anda dan format kad lama tidak diterima.",
      validation_ID_in: "Panjang antara 8 hingga 12 termasuk",
      validation_ID_jp: "Panjang antara 9 hingga 12 termasuk",
      validation_DL_my:
          "Sila masukkan antara 10 hingga 14 aksara nombor lesen memandu anda.",
      validation_DL_ph: "Sila masukkan 11 aksara nombor lesen memandu anda.",
      validation_DL_in: "Panjang antara 15 hingga 16 termasuk",
      validation_DL_jp: "Panjang antara 9 hingga 12 termasuk",
      validation_DL_ng: "Panjang antara 8 hingga 13 termasuk",
      validation_DL_gh: "Panjang antara 8 hingga 16 termasuk",
      validation_DL_za: "Panjang antara 8 hingga 13 termasuk",
      validation_UID_in: "Numerik. Panjang 12",
      validation_PASS_ph: "Sila masukkan 9 aksara nombor pasport anda.",
      validation_PASS_la: "Alfanumerik. Satu aksara diikuti oleh 7 digit",
      validation_PASS_jp: "Panjang antara 9 hingga 12 termasuk",
      validation_PASS_ng: "Panjang antara 9 hingga 12 termasuk",
      validation_PASS_gh: "Panjang antara 9 hingga 12 termasuk",
      validation_PASS_za: "Panjang antara 9 hingga 12 termasuk",
      validation_RD_jp: "Panjang antara 9 hingga 12 termasuk",
      validation_PCN_ph: "Sila masukkan 16 aksara nombor PCN anda.",
      validation_CRN_ph: "Sila masukkan 12 aksara nombor CRN anda.",
    },

    adminRejectTopic: "Kami memohon maaf atas kesulitan yang timbul.",
    adminRejectReason:
        "Kami memohon maaf atas kesulitan yang timbul. Satu isu telah dikenal pasti dengan maklumat yang diberikan, yang memerlukan penilaian semula. Sila hantar semula bukti pengenalan anda untuk penilaian lanjut.",
    bookbankverifyTitle: "Bukti pengesahan bank",
    kycTitle: "	Bukti Pengesahan Identiti	",
    step1: "	Sahkan dokumen	",
    step2: "	Maklumat asas	",
    step3: "	Mengesahkan kejayaan	",
    continue: "	Teruskan	",
    verifyidentity: "	Sahkan identiti anda	",
    acceptedfile:
        "	Jenis fail yang diterima ialah saiz fail JPG, JPEG, PNG dan Max ialah 8 MB.	",
    cameradevice: "	Peranti kamera	",
    uploadfile: "	Muat naik fail	",
    or: "	atau	",
    anotherdevice: "	Gunakan peranti lain	",
    anotherdeviceDetail:
        "	Maklumat peribadi anda selamat. Data yang diekstrak dari dokumen ID anda kekal di dalam pasaran IUX dan tidak pernah dikongsi dengan syarikat lain.	",
    scancode: "	Imbas kod di bawah untuk menggunakan peranti lain.	",
    back: "	Kembali	",
    verificationFailed: "	Pengesahan gagal	",
    sorryverify: "	Maaf! Kami tidak dapat mengesahkan identiti anda	",
    needsupport:
        "	Perlukan bantuan? Kami berada di sini untuk membantu anda 24/7 \n sila hubungi kami melalui sembang langsung.",
    uploadAgian: "	Muat naik lagi",
    tempblock: "	Percubaan pengesahan telah disekat buat sementara waktu	",
    tempblockdetail:
        "	Percubaan pengesahan identiti telah disekat buat sementara waktu selama 5 minit. Sila cuba memuat naik dokumen lain di kemudian hari.	",
    permanently: "	Percubaan pengesahan disekat secara kekal	",
    permanentlydetail:
        "	Percubaan pengesahan identiti anda telah disekat secara kekal. Sila hubungi sokongan pasaran IUX melalui sembang langsung untuk mendapatkan bantuan lanjut.	",
    countdown: "	undur	",
    loadingtitle: "	Semasa pemprosesan ini, sila pastikan halaman ini dibuka	",
    autoverify: "Pengesahan automatik",
    tutorial: "Tutorial",
    manualverify: "	Pengesahan identiti manual	",
    trydemo: "	Cuba demo	",
    verifySuccess: "	Kejayaan pengesahan	",
    verifySuccessdetial: "	Identiti anda telah disahkan.	",
    manualtitle: "	Dokumen identiti	",
    manualsubtitle: "	Sila muat naik foto",
    manualsubtitle2:
        "Foto itu harus cerah dan jelas, dan semua penyambung dokumen anda mesti kelihatan.",
    fontcard: "	Depan	",
    backcard: "	Kembali	",
    manualselfie: "	Muat naik selfie dengan bukti	",
    manualselfiedetail:
        "	Sila muat naik selfie dengan dokumen Aadhaar anda dan laman web IUX dibuka di pelayar Internet dalam foto yang sama. Pastikan kedua -duanya kelihatan jelas dan wajah anda tidak dilindungi.	",
    otpverifytitle: "	Pengesahan OTP	",
    invalidphone: "	Telefon tidak sah, sila edit nombor telefon anda.	",
    requestotp: "	Minta OTP	",
    enterotp: "	Masukkan kod dari SMS yang kami hantar ke	",
    codeexpiresin: "	Kod tamat tempoh	",
    seconds: "	saat	",
    codenotmatch: "	Kod tidak sepadan.	",
    bookbankuploadtitle:
        "	Sila ambil gambar buku bank anda dan pastikan dokumen anda memenuhi keperluan berikut",
    bookbankuploaddetail1: "	Kualiti yang baik, cerah dan jelas.	",
    bookbankuploaddetail2: "	Jenis fail JPG, JPEG, PNG, PDF.	",
    bookbankuploaddetail3: "	Semua sudut dokumen anda mesti kelihatan.	",
    bookbankfilesize: "	Saiz fail tidak boleh melebihi 8MB.	",
    didotp: "Tidak menerima OTP?",
    error_verify_type_file: "Maaf, jenis fail tidak boleh diterima.",
    error_verify_kyc:
        "Tidak berjaya, kami tidak dapat mengekstrak maklumat dari imej.",
    inprogress: "Sedang berjalan",
    sendverifysuccess:
        "Berjaya dikemukakan! Ia biasanya mengambil masa 3-15 minit untuk kami menyelesaikan cek yang diperlukan dan mengesahkan dokumen anda.",
    kyc_scanning: "	Mengimbas ...	",
    kyc_detected: "	Pengesanan berjaya	",
    kyc_angle: "	Laraskan sudut	",
    kyc_fail: "Pengesanan gagal",
    kyc_move_far: "Gerakkan dokumen lebih jauh",
    kyc_move: "	Pindahkan dokumen lebih dekat	",
  },
  id: {
    something: "Terjadi kesalahan",
    tryagain: "Silakan coba lagi",

    documentType: {
      ID: "Kartu Identitas",
      DL: "Surat Izin Mengemudi",
      UID: "Nomor Identifikasi Unik (Aadhaar)",
      PASS: "Paspor",
      RD: "Kartu Penduduk",
      PCN: "Kartu ID nasional Filipina (PhilID)",
      CRN: "ID Serbaguna Terpadu",
      validation_ID_th:
          "Silakan masukkan antara 10 hingga 14 karakter nomor ID Anda.",
      validation_ID_my:
          "Harap masukkan antara 10 hingga 14 karakter nomor MyKad Anda.",
      validation_ID_la:
          "Harap masukkan antara 9 hingga 14 karakter nomor ID Anda.",
      validation_ID_ng:
          "Harap masukkan antara 9 hingga 14 karakter nomor ID Anda.",
      validation_ID_gh:
          "Harap masukkan antara 9 hingga 17 karakter nomor ID Anda.",
      validation_ID_za:
          "Harap masukkan antara 9 hingga 14 karakter nomor ID Anda.",
      validation_ID_id:
          "Silakan masukkan antara 14 hingga 18 karakter nomor NIK Anda.",
      validation_NIK_id:
          "Silakan masukkan antara 14 hingga 18 karakter nomor NIK Anda.",
      validation_ID_vn:
          "Silakan masukkan antara 9 hingga 13 karakter nomor ID Anda dan format kartu lama tidak diterima.",
      validation_ID_in: "Panjang antara 8 hingga 12 termasuk",
      validation_ID_jp: "Panjang antara 9 hingga 12 termasuk",
      validation_DL_my:
          "Silakan masukkan antara 10 hingga 14 karakter nomor SIM Anda.",
      validation_DL_ph: "Silakan masukkan 11 karakter nomor SIM Anda.",
      validation_DL_in: "Panjang antara 15 hingga 16 termasuk",
      validation_DL_jp: "Panjang antara 9 hingga 12 termasuk",
      validation_DL_ng: "Panjang antara 8 hingga 13 termasuk",
      validation_DL_gh: "Panjang antara 8 hingga 16 termasuk",
      validation_DL_za: "Panjang antara 8 hingga 13 termasuk",
      validation_UID_in: "Numerik. Panjang 12",
      validation_PASS_ph: "Silakan masukkan 9 karakter nomor paspor Anda.",
      validation_PASS_la: "Alfanumerik. Satu karakter diikuti oleh 7 digit",
      validation_PASS_jp: "Panjang antara 9 hingga 12 termasuk",
      validation_PASS_ng: "Panjang antara 9 hingga 12 termasuk",
      validation_PASS_gh: "Panjang antara 9 hingga 12 termasuk",
      validation_PASS_za: "Panjang antara 9 hingga 12 termasuk",
      validation_RD_jp: "Panjang antara 9 hingga 12 termasuk",
      validation_PCN_ph: "Silakan masukkan 16 karakter nomor PCN Anda.",
      validation_CRN_ph: "Silakan masukkan 12 karakter nomor CRN Anda.",
    },

    adminRejectTopic: "Kami meminta maaf atas ketidaknyamanan yang disebabkan.",
    adminRejectReason:
        "Kami meminta maaf atas ketidaknyamanan yang disebabkan. Masalah telah diidentifikasi dengan informasi yang diberikan, yang memerlukan peninjauan ulang. Silakan kirim kembali bukti identitas Anda untuk tinjauan lebih lanjut.",
    bookbankverifyTitle: "Bukti Verifikasi Bank",
    kycTitle: "	Bukti Verifikasi Identitas	",
    step1: "	Verifikasi dokumen	",
    step2: "	Informasi dasar	",
    step3: "	Verifikasi kesuksesan	",
    continue: "	Melanjutkan	",
    verifyidentity: "	Verifikasi identitas Anda	",
    acceptedfile:
        "	Jenis file yang diterima adalah ukuran file JPG, JPEG, PNG dan Max adalah 8 MB.	",
    cameradevice: "	Perangkat kamera	",
    uploadfile: "	Unggah data	",
    or: "	atau	",
    anotherdevice: "	Gunakan perangkat lain	",
    anotherdeviceDetail:
        "	Informasi pribadi Anda aman. Data yang diekstraksi dari dokumen ID Anda tetap berada di pasar IUX dan tidak pernah dibagikan dengan perusahaan lain.	",
    scancode: "	Pindai kode di bawah ini untuk menggunakan perangkat lain.	",
    back: "	Kembali	",
    verificationFailed: "	Verifikasi gagal	",
    sorryverify: "	Maaf! Kami tidak dapat memverifikasi identitas Anda	",
    needsupport:
        "	Butuh Bantuan? Kami di sini untuk membantu Anda 24/7 \n silakan hubungi kami melalui obrolan langsung.",
    uploadAgian: "	Unggah lagi	",
    tempblock: "	Upaya verifikasi diblokir sementara	",
    tempblockdetail:
        "	Upaya verifikasi identitas telah diblokir sementara selama 5 menit. Mohon cobalah mengunggah dokumen lain di lain waktu.	",
    permanently: "	Upaya verifikasi diblokir secara permanen	",
    permanentlydetail:
        "	Upaya verifikasi identitas Anda telah diblokir secara permanen. Silakan hubungi dukungan pasar IUX melalui obrolan langsung untuk bantuan lebih lanjut.	",
    countdown: "	Hitung mundur	",
    loadingtitle: "	Saat pemrosesan ini, harap buka halaman ini	",
    autoverify: "Verifikasi otomatis",
    tutorial: "Tutorial",
    manualverify: "	Verifikasi Identitas Manual	",
    trydemo: "	Coba demo	",
    verifySuccess: "	Keberhasilan verifikasi	",
    verifySuccessdetial: "	Identitas Anda telah diverifikasi.	",
    manualtitle: "	Dokumen identitas	",
    manualsubtitle: "	Harap unggah foto",
    manualsubtitle2:
        "Foto harus cerah dan jelas, dan semua Conners dari dokumen Anda harus terlihat.",
    fontcard: "	Depan	",
    backcard: "	Kembali	",
    manualselfie: "	Unggah selfie dengan buktinya	",
    manualselfiedetail:
        "	Harap unggah selfie dengan dokumen Aadhaar Anda dan situs web IUX dibuka di browser internet di foto yang sama. Pastikan keduanya terlihat jelas dan wajah Anda tidak tertutup.	",
    otpverifytitle: "	Verifikasi OTP	",
    invalidphone: "	Telepon tidak valid, edit nomor telepon Anda.	",
    requestotp: "	Meminta OTP	",
    enterotp: "	Masukkan kode dari SMS yang kami kirim ke	",
    codeexpiresin: "	Kode berakhir	",
    seconds: "	detik	",
    codenotmatch: "	Kode tidak cocok.	",
    bookbankuploadtitle:
        "	Silakan ambil gambar buku tabungan Anda dan pastikan dokumen Anda memenuhi persyaratan berikut",
    bookbankuploaddetail1: "	Kualitas bagus, cerah dan jernih.	",
    bookbankuploaddetail2: "	Jenis file JPG, JPEG, PNG, PDF.	",
    bookbankuploaddetail3: "	Semua sudut dokumen Anda harus terlihat.	",
    bookbankfilesize: "	Ukuran file tidak dapat melebihi 8MB.	",
    didotp: "Tidak menerima OTP?",
    error_verify_type_file: "Maaf, jenis file tidak dapat diterima.",
    error_verify_kyc:
        "Tidak berhasil, kami tidak dapat mengekstrak informasi dari gambar.",
    inprogress: "Sedang berlangsung",
    sendverifysuccess:
        "Berhasil dikirim! Biasanya hanya membutuhkan 3-15 menit bagi kami untuk menyelesaikan cek yang diperlukan dan mengkonfirmasi dokumen Anda.",
    kyc_scanning: "	Memindai ...	",
    kyc_detected: "	Deteksi berhasil	",
    kyc_angle: "	Sesuaikan sudut	",
    kyc_fail: "Deteksi gagal",
    kyc_move_far: "Pindahkan dokumen lebih jauh",
    kyc_move: "	Pindahkan dokumen lebih dekat	",
  },
  zh: {
    something: "出了点问题",
    tryagain: "请再试一次",

    documentType: {
      ID: "身份证",
      DL: "驾驶执照",
      UID: "唯一识别号码（阿达尔）",
      PASS: "护照",
      RD: "居住卡",
      PCN: "菲律宾国民身份证 (PhilID)",
      CRN: "统一多用途身份证",
      validation_ID_th: "请输入您的身份证号码的10到14个字符。",
      validation_ID_my: "请输入您的MyKad号码，字符数在10到14之间。",
      validation_ID_la: "请输入您的ID号码，字符数在9到14之间。",
      validation_ID_ng: "请输入您的ID号码，字符数在9到14之间。",
      validation_ID_gh: "请输入您的ID号码，字符数在9到17之间。",
      validation_ID_za: "请输入您的ID号码，字符数在9到14之间。",
      validation_ID_id: "请输入您的NIK号码的14到18个字符。",
      validation_NIK_id: "请输入您的NIK号码的14到18个字符。",
      validation_ID_vn: "请输入您的身份证号码的9到13个字符，旧卡格式不接受。",
      validation_ID_in: "长度在8到12个字符之间",
      validation_ID_jp: "长度在9到12个字符之间",
      validation_DL_my: "请输入您的驾驶执照号码的10到14个字符。",
      validation_DL_ph: "请输入您的驾驶执照号码的11个字符。",
      validation_DL_in: "长度在15到16个字符之间",
      validation_DL_jp: "长度在9到12个字符之间",
      validation_DL_ng: "长度在8到13个字符之间",
      validation_DL_gh: "长度在8到16个字符之间",
      validation_DL_za: "长度在8到13个字符之间",
      validation_UID_in: "数字。长度12",
      validation_PASS_ph: "请输入您的护照号码的9个字符。",
      validation_PASS_la: "字母数字。一个字符后跟7位数字",
      validation_PASS_jp: "长度在9到12个字符之间",
      validation_PASS_ng: "长度在9到12个字符之间",
      validation_PASS_gh: "长度在9到12个字符之间",
      validation_PASS_za: "长度在9到12个字符之间",
      validation_RD_jp: "长度在9到12个字符之间",
      validation_PCN_ph: "请输入您的PCN号码的16个字符。",
      validation_CRN_ph: "请输入您的CRN号码的12个字符。",
    },

    adminRejectTopic: "对造成的不便我们深感抱歉。",
    adminRejectReason:
        "对造成的不便我们深感抱歉。已经发现所提供信息存在问题，需要重新评估。请重新提交您的身份证明以供进一步审查。",
    bookbankverifyTitle: "銀行驗證證明",
    kycTitle: "	身份證明驗證	",
    step1: "	驗證文檔	",
    step2: "	基本信息	",
    step3: "	驗證成功	",
    continue: "	繼續	",
    verifyidentity: "	驗證您的身份	",
    acceptedfile: "	接受的文件類型是JPG，JPEG，PNG和最大文件大小為8 MB。	",
    cameradevice: "	相機設備	",
    uploadfile: "	上傳文件	",
    or: "	或者	",
    anotherdevice: "	使用另一個設備	",
    anotherdeviceDetail:
        "	您的個人信息是安全的。從您的ID文檔中提取的數據停留在IUX市場中，從未與其他公司共享。	",
    scancode: "	掃描下面的代碼以使用其他設備。	",
    back: "	後退	",
    verificationFailed: "	驗證失敗	",
    sorryverify: "	對不起！我們無法驗證您的身份	",
    needsupport:
        "	需要幫助？我們在這里為24/7 \n提供幫助，請通過實時聊天與我們聯繫。",
    uploadAgian: "	再次上傳	",
    tempblock: "	驗證嘗試被暫時阻止	",
    tempblockdetail:
        "	身份驗證嘗試已暫時阻止5分鐘。請嘗試在以後上傳另一個文檔。	",
    permanently: "	驗證嘗試被永久阻止	",
    permanentlydetail:
        "	您的身份驗證嘗試已被永久阻止。請通過實時聊天與IUX市場支持聯繫，以獲取進一步的幫助。	",
    countdown: "	倒數	",
    loadingtitle: "	在此處理時，請保持此頁面打開	",
    autoverify: "自动验证",
    tutorial: "教程",
    manualverify: "	手動身份驗證	",
    trydemo: "	嘗試演示	",
    verifySuccess: "	驗證成功	",
    verifySuccessdetial: "	您的身份已得到驗證。	",
    manualtitle: "	身份證件	",
    manualsubtitle: "請上傳一張照片",
    manualsubtitle2: "照片應該明亮且清晰，並且文檔的所有鏈接都必須可見。",
    fontcard: "	正面	",
    backcard: "	後退	",
    manualselfie: "	用證據上傳自拍照	",
    manualselfiedetail:
        "	請使用您的Aadhaar文檔和IUX市場網站在同一張照片中打開的自拍照。確保兩者都清晰可見，並且您的臉沒有覆蓋。	",
    otpverifytitle: "	OTP驗證	",
    invalidphone: "	無效的電話，請編輯您的電話號碼。	",
    requestotp: "	請求OTP	",
    enterotp: "	輸入我們發送到的SMS的代碼	",
    codeexpiresin: "	代碼到期	",
    seconds: "	秒	",
    codenotmatch: "	代碼不匹配。	",
    bookbankuploadtitle: "	请拍摄您的银行存折照片，并确保您的文件符合以下要求",
    bookbankuploaddetail1: "	優質，明亮而清晰。	",
    bookbankuploaddetail2: "	文件類型JPG，JPEG，PNG，PDF。	",
    bookbankuploaddetail3: "	文檔的所有角落都必須可見。	",
    bookbankfilesize: "	文件大小不能超過8MB。	",
    didotp: "沒有收到OTP？",
    error_verify_type_file: "抱歉，文件類型無法接受。",
    error_verify_kyc: "失敗，我們無法從圖像中提取信息。",
    inprogress: "進行中",
    sendverifysuccess:
        "成功提交！我們通常只需要3-15分鐘即可完成必要的檢查並確認您的文檔。",
    kyc_scanning: "	掃描...	",
    kyc_detected: "	檢測成功	",
    kyc_angle: "	調整角度	",
    kyc_fail: "檢測失敗",
    kyc_move_far: "將文檔移至更遠的地方",
    kyc_move: "	將文檔靠近移動	",
  },
  vi: {
    something: "Đã xảy ra lỗi",
    tryagain: "Vui lòng thử lại",

    documentType: {
      ID: "Thẻ Căn cước",
      DL: "Giấy phép lái xe",
      UID: "Số Định danh Du nhất (Aadhaar)",
      PASS: "Hộ chiếu",
      RD: "Thẻ Cư trú",
      PCN: "Thẻ ID quốc gia Philippines (PhilID)",
      CRN: "ID Đa năng Thống nhất",
      validation_ID_th: "Vui lòng nhập từ 10 đến 14 ký tự của số ID của bạn.",
      validation_ID_my:
          "Vui lòng nhập từ 10 đến 14 ký tự của số MyKad của bạn.",
      validation_ID_la: "Vui lòng nhập từ 9 đến 14 ký tự của số ID của bạn.",
      validation_ID_ng: "Vui lòng nhập từ 9 đến 14 ký tự của số ID của bạn.",
      validation_ID_gh: "Vui lòng nhập từ 9 đến 17 ký tự của số ID của bạn.",
      validation_ID_za: "Vui lòng nhập từ 9 đến 14 ký tự của số ID của bạn.",
      validation_ID_id: "Vui lòng nhập từ 14 đến 18 ký tự của số NIK của bạn.",
      validation_NIK_id: "Vui lòng nhập từ 14 đến 18 ký tự của số NIK của bạn.",
      validation_ID_vn:
          "Vui lòng nhập từ 9 đến 13 ký tự của số ID của bạn và không chấp nhận định dạng thẻ cũ.",
      validation_ID_in: "Chiều dài từ 8 đến 12 ký tự",
      validation_ID_jp: "Chiều dài từ 9 đến 12 ký tự",
      validation_DL_my:
          "Vui lòng nhập từ 10 đến 14 ký tự của số Giấy phép lái xe của bạn.",
      validation_DL_ph:
          "Vui lòng nhập 11 ký tự của số Giấy phép lái xe của bạn.",
      validation_DL_in: "Chiều dài từ 15 đến 16 ký tự",
      validation_DL_jp: "Chiều dài từ 9 đến 12 ký tự",
      validation_DL_ng: "Chiều dài từ 8 đến 13 ký tự",
      validation_DL_gh: "Chiều dài từ 8 đến 16 ký tự",
      validation_DL_za: "Chiều dài từ 8 đến 13 ký tự",
      validation_UID_in: "Chỉ số. Chiều dài 12",
      validation_PASS_ph: "Vui lòng nhập 9 ký tự của số Hộ chiếu của bạn.",
      validation_PASS_la: "Chữ và số. Một ký tự theo sau là 7 chữ số",
      validation_PASS_jp: "Chiều dài từ 9 đến 12 ký tự",
      validation_PASS_ng: "Chiều dài từ 9 đến 12 ký tự",
      validation_PASS_gh: "Chiều dài từ 9 đến 12 ký tự",
      validation_PASS_za: "Chiều dài từ 9 đến 12 ký tự",
      validation_RD_jp: "Chiều dài từ 9 đến 12 ký tự",
      validation_PCN_ph: "Vui lòng nhập 16 ký tự của số PCN của bạn.",
      validation_CRN_ph: "Vui lòng nhập 12 ký tự của số CRN của bạn.",
    },

    adminRejectTopic: "Chúng tôi xin lỗi vì sự bất tiện gây ra.",
    adminRejectReason:
        "Chúng tôi xin lỗi vì sự bất tiện gây ra. Đã xác định một vấn đề với thông tin đã cung cấp, đòi hỏi xem xét lại. Vui lòng gửi lại chứng minh nhân dân của bạn để được xem xét thêm.",
    bookbankverifyTitle: "Bằng chứng xác minh ngân hàng",
    kycTitle: "	Bằng chứng xác minh danh tính	",
    step1: "	Xác minh tài liệu	",
    step2: "	Thông tin cơ bản	",
    step3: "	Xác minh thành công	",
    continue: "	Tiếp tục	",
    verifyidentity: "	Xác minh danh tính của bạn	",
    acceptedfile:
        "	Các loại tệp được chấp nhận là kích thước tệp JPG, JPEG, PNG và Max là 8 MB.	",
    cameradevice: "	Thiết bị máy ảnh	",
    uploadfile: "	Cập nhật dử liệu	",
    or: "	hoặc	",
    anotherdevice: "	Sử dụng một thiết bị khác	",
    anotherdeviceDetail:
        "	thông tin cá nhân của bạn được an toàn. Dữ liệu được trích xuất từ ​​tài liệu ID của bạn ở trong thị trường IUX và không bao giờ được chia sẻ với các công ty khác.	",
    scancode: "	Quét mã bên dưới để sử dụng một thiết bị khác.	",
    back: "	Mặt sau	",
    verificationFailed: "	Xác minh không hoàn thành	",
    sorryverify: "	Lấy làm tiếc! Chúng tôi không thể xác minh danh tính của bạn	",
    needsupport:
        "Cần sự trợ giúp? Chúng tôi ở đây để giúp bạn 24/7  \n Vui lòng liên hệ với chúng tôi qua trò chuyện trực tiếp.",
    uploadAgian: "	Tải lên một lần nữa	",
    tempblock: "	Nỗ lực xác minh đã bị chặn tạm thời	",
    tempblockdetail:
        "	Nỗ lực xác minh nhận dạng đã bị chặn tạm thời trong thời gian 5 phút. Vui lòng thử tải lên một tài liệu khác sau đó.	",
    permanently: "	Nỗ lực xác minh đã bị chặn vĩnh viễn	",
    permanentlydetail:
        "	Nỗ lực xác minh danh tính của bạn đã bị chặn vĩnh viễn. Vui lòng liên hệ với hỗ trợ của thị trường IUX thông qua trò chuyện trực tiếp để được hỗ trợ thêm.	",
    countdown: "	Đếm ngược	",
    loadingtitle: "	Trong khi xử lý này, vui lòng mở trang này	",
    autoverify: "Xác minh tự động",
    tutorial: "Hướng dẫn",
    manualverify: "	Xác minh nhận dạng thủ công	",
    trydemo: "	Hãy thử bản demo	",
    verifySuccess: "	Xác minh thành công	",
    verifySuccessdetial: "	Danh tính của bạn đã được xác minh.	",
    manualtitle: "	Tài liệu nhận dạng	",
    manualsubtitle: "Vui lòng tải lên một bức ảnh của",
    manualsubtitle2:
        "Ảnh phải sáng và rõ ràng, và tất cả các kết nối tài liệu của bạn phải được nhìn thấy.",
    fontcard: "	Đằng trước	",
    backcard: "	Mặt sau	",
    manualselfie: "	Tải lên selfie với bằng chứng	",
    manualselfiedetail:
        "	Vui lòng tải lên một selfie với tài liệu Aadhaar của bạn và trang web IUX Market được mở trong trình duyệt Internet trong cùng một bức ảnh. Đảm bảo rằng cả hai đều có thể nhìn thấy rõ và khuôn mặt của bạn không được bảo hiểm.	",
    otpverifytitle: "	Xác minh OTP	",
    invalidphone:
        "	Điện thoại không hợp lệ, vui lòng chỉnh sửa số điện thoại của bạn.	",
    requestotp: "	Yêu cầu OTP	",
    enterotp: "	Nhập mã từ SMS mà chúng tôi đã gửi đến	",
    codeexpiresin: "	Mã hết hạn	",
    seconds: "	giây	",
    codenotmatch: "	Mã không phù hợp.	",
    bookbankuploadtitle:
        "	Vui lòng chụp ảnh sổ ngân hàng của bạn và đảm bảo tài liệu của bạn đáp ứng các yêu cầu sau đây",
    bookbankuploaddetail1: "	Chất lượng tốt, tươi sáng và rõ ràng.	",
    bookbankuploaddetail2: "	Loại tệp JPG, JPEG, PNG, PDF.	",
    bookbankuploaddetail3:
        "	Tất cả các góc của tài liệu của bạn phải được nhìn thấy.	",
    bookbankfilesize: "	Kích thước tệp không thể vượt quá 8MB.	",
    didotp: "Didn nhận được OTP?",
    error_verify_type_file: "Xin lỗi, loại tệp không thể được chấp nhận.",
    error_verify_kyc:
        "Không thành công, chúng tôi không thể trích xuất thông tin từ hình ảnh.",
    inprogress: "Trong tiến trình",
    sendverifysuccess:
        "Đã gửi thành công! Thông thường chỉ mất 3-15 phút để chúng tôi hoàn thành các séc cần thiết và xác nhận tài liệu của bạn.",
    kyc_scanning: "	Quét ...	",
    kyc_detected: "	Phát hiện thành công	",
    kyc_angle: "	Điều chỉnh góc	",
    kyc_fail: "Phát hiện không thành công",
    kyc_move_far: "Di chuyển tài liệu xa hơn",
    kyc_move: "	Di chuyển tài liệu gần hơn	",
  },
  ko: {
    something: "문제가 발생했습니다",
    tryagain: "다시 시도해 주세요",

    documentType: {
      ID: "신분증",
      DL: "운전면허증",
      UID: "고유 식별 번호 (아다르)",
      PASS: "여권",
      RD: "거주 카드",
      PCN: "필리핀 국민 ID 카드 (PhilID)",
      CRN: "통합 다목적 ID",
      validation_ID_th: "ID 번호의 10자에서 14자 사이를 입력해 주세요.",
      validation_ID_my: "MyKad 번호를 10자에서 14자 사이로 입력하세요.",
      validation_ID_la: "ID 번호를 9자에서 14자 사이로 입력하세요.",
      validation_ID_ng: "ID 번호를 9자에서 14자 사이로 입력하세요.",
      validation_ID_gh: "ID 번호를 9자에서 17자 사이로 입력하세요.",
      validation_ID_za: "ID 번호를 9자에서 14자 사이로 입력하세요.",
      validation_ID_id: "NIK 번호의 14자에서 18자 사이를 입력해 주세요.",
      validation_NIK_id: "NIK 번호의 14자에서 18자 사이를 입력해 주세요.",
      validation_ID_vn:
          "ID 번호의 9자에서 13자 사이를 입력하고, 오래된 카드 형식은 허용되지 않습니다.",
      validation_ID_in: "8자에서 12자 사이의 길이",
      validation_ID_jp: "9자에서 12자 사이의 길이",
      validation_DL_my: "운전면허 번호의 10자에서 14자 사이를 입력해 주세요.",
      validation_DL_ph: "운전면허 번호의 11자를 입력해 주세요.",
      validation_DL_in: "15자에서 16자 사이의 길이",
      validation_DL_jp: "9자에서 12자 사이의 길이",
      validation_DL_ng: "8자에서 13자 사이의 길이",
      validation_DL_gh: "8자에서 16자 사이의 길이",
      validation_DL_za: "8자에서 13자 사이의 길이",
      validation_UID_in: "숫자. 길이 12자",
      validation_PASS_ph: "여권 번호의 9자를 입력해 주세요.",
      validation_PASS_la: "알파숫자. 한 문자 다음에 7자리 숫자",
      validation_PASS_jp: "9자에서 12자 사이의 길이",
      validation_PASS_ng: "9자에서 12자 사이의 길이",
      validation_PASS_gh: "9자에서 12자 사이의 길이",
      validation_PASS_za: "9자에서 12자 사이의 길이",
      validation_RD_jp: "9자에서 12자 사이의 길이",
      validation_PCN_ph: "PCN 번호의 16자를 입력해 주세요.",
      validation_CRN_ph: "CRN 번호의 12자를 입력해 주세요.",
    },

    adminRejectTopic: "발생한 불편에 대해 사과드립니다.",
    adminRejectReason:
        "발생한 불편에 대해 사과드립니다. 제공된 정보에 문제가 발견되어 재평가가 필요합니다. 추가 검토를 위해 신원증명서를 다시 제출해주십시오.",
    bookbankverifyTitle: "은행 검증의 증거",
    kycTitle: "	신원 검증 증명	",
    step1: "	문서를 확인하십시오	",
    step2: "	기본 정보	",
    step3: "	성공을 확인하십시오	",
    continue: "	계속하다	",
    verifyidentity: "	귀하의 신원을 확인하십시오	",
    acceptedfile:
        "	허용되는 파일 유형은 JPG, JPEG, PNG 및 최대 파일 크기는 8MB입니다.	",
    cameradevice: "	카메라 장치	",
    uploadfile: "	파일 업로드	",
    or: "	또는	",
    anotherdevice: "	다른 장치를 사용하십시오	",
    anotherdeviceDetail:
        "	귀하의 개인 정보는 안전합니다. ID 문서에서 추출한 데이터는 IUX 시장 내에 유지되며 다른 회사와 공유되지 않습니다.	",
    scancode: "	다른 장치를 사용하려면 아래 코드를 스캔하십시오.	",
    back: "	뒤쪽에	",
    verificationFailed: "	확인이 실패했습니다	",
    sorryverify: "	죄송합니다! 우리는 당신의 신원을 확인할 수 없었습니다	",
    needsupport:
        "도움이 필요하다? 24/7 \n 라이브 채팅을 통해 저희에게 연락하십시오.",
    uploadAgian: "	다시 업로드하십시오	",
    tempblock: "	검증 시도가 일시적으로 차단되었습니다	",
    tempblockdetail:
        "	신원 확인 시도는 5 분 동안 일시적으로 차단되었습니다. 나중에 다른 문서를 업로드하십시오.	",
    permanently: "	검증 시도가 영구적으로 차단되었습니다	",
    permanentlydetail:
        "	귀하의 신원 확인 시도가 영구적으로 차단되었습니다. 추가 지원을 위해 라이브 채팅을 통해 IUX 지원에 문의하십시오.	",
    countdown: "	카운트 다운	",
    autoverify: "자동 확인",
    tutorial: "튜토리얼",
    manualverify: "	수동 신원 확인	",
    trydemo: "	데모를 시도하십시오	",
    verifySuccess: "	검증 성공	",
    verifySuccessdetial: "	귀하의 신원이 확인되었습니다.	",
    manualtitle: "	신원 문서	",
    manualsubtitle: "	사진을 업로드하십시오",
    manualsubtitle2:
        "사진은 밝고 명확해야하며 문서의 모든 연결이 보일 것입니다.",
    fontcard: "	앞쪽	",
    backcard: "	뒤쪽에	",
    manualselfie: "	증거로 셀카를 업로드하십시오	",
    manualselfiedetail:
        "	Aadhaar 문서와 IUX 웹 사이트가 같은 사진으로 인터넷 브라우저에 열린 셀카를 업로드하십시오. 둘 다 명확하게 보이고 얼굴이 덮여 있지 않은지 확인하십시오.	",
    otpverifytitle: "	OTP 검증	",
    invalidphone: "	잘못된 전화, 전화 번호를 친절하게 편집하십시오.	",
    requestotp: "	OTP를 요청하십시오	",
    enterotp: "	보낸 SMS에서 코드를 입력하십시오	",
    codeexpiresin: "	코드가 만료됩니다	",
    seconds: "	초	",
    codenotmatch: "	코드는 일치하지 않습니다.	",
    bookbankuploadtitle:
        "	당신의 은행 통장을 사진 찍어서 문서가 다음 요건을 충족하는지 확인하세요",
    bookbankuploaddetail1: "	좋은 품질, 밝고 명확합니다.	",
    bookbankuploaddetail2: "	파일 유형 JPG, JPEG, PNG, PDF.	",
    bookbankuploaddetail3: "	문서의 모든 모서리가 표시되어야합니다.	",
    bookbankfilesize: "	파일 크기는 8MB를 초과 할 수 없습니다.	",
    didotp: "OTP를받지 못했습니까?",
    error_verify_type_file: "죄송합니다. 파일 유형을 수락 할 수 없습니다.",
    error_verify_kyc: "실패하면 이미지에서 정보를 추출 할 수 없었습니다.",
    inprogress: "진행 중",
    sendverifysuccess:
        "성공적으로 제출되었습니다! 일반적으로 필요한 수표를 작성하고 문서를 확인하는 데 3-15 분이 걸립니다.",
    kyc_scanning: "	스캐닝...	",
    kyc_detected: "	감지 성공	",
    kyc_angle: "	각도를 조정하십시오	",
    kyc_fail: "탐지가 실패했습니다",
    kyc_move_far: "문서를 더 멀리 이동하십시오",
    kyc_move: "	문서를 가까이으로 이동하십시오	",
  },
  fil: {
    something: "May mali na nangyari",
    tryagain: "Pakiusap, subukan muli",

    documentType: {
      ID: "Kard ng Pagkakakilanlan",
      DL: "Lisensya sa Pagmamaneho",
      UID: "Natatanging Bilang ng Pagkakakilanlan (Aadhaar)",
      PASS: "Pasaporte",
      RD: "Kard ng Paninirahan",
      PCN: "Pambansang ID card ng Pilipinas (PhilID)",
      CRN: "Pinag-isang Multi-Purpose ID",
      validation_ID_th:
          "Mangyaring ilagay ang 10 hanggang 14 na karakter ng iyong numero ng ID.",
      validation_ID_my:
          "Mangyaring ilagay ang 10 hanggang 14 na karakter ng iyong MyKad number.",
      validation_ID_la:
          "Mangyaring ilagay ang 9 hanggang 14 na karakter ng iyong ID number.",
      validation_ID_ng:
          "Mangyaring ilagay ang 9 hanggang 14 na karakter ng iyong ID number.",
      validation_ID_gh:
          "Mangyaring ilagay ang 9 hanggang 17 na karakter ng iyong ID number.",
      validation_ID_za:
          "Mangyaring ilagay ang 9 hanggang 14 na karakter ng iyong ID number.",
      validation_ID_id:
          "Mangyaring ilagay ang 14 hanggang 18 na karakter ng iyong numero ng NIK.",
      validation_NIK_id:
          "Mangyaring ilagay ang 14 hanggang 18 na karakter ng iyong numero ng NIK.",
      validation_ID_vn:
          "Mangyaring ilagay ang 9 hanggang 13 na karakter ng iyong numero ng ID at hindi katanggap-tanggap ang lumang format ng kard.",
      validation_ID_in: "Habang nasa pagitan ng 8 at 12 kasama",
      validation_ID_jp: "Habang nasa pagitan ng 9 at 12 kasama",
      validation_DL_my:
          "Mangyaring ilagay ang 10 hanggang 14 na karakter ng iyong numero ng Lisensya sa Pagmamaneho.",
      validation_DL_ph:
          "Mangyaring ilagay ang 11 karakter ng iyong numero ng Lisensya sa Pagmamaneho.",
      validation_DL_in: "Habang nasa pagitan ng 15 at 16 kasama",
      validation_DL_jp: "Habang nasa pagitan ng 9 at 12 kasama",
      validation_DL_ng: "Habang nasa pagitan ng 8 at 13 kasama",
      validation_DL_gh: "Habang nasa pagitan ng 8 at 16 kasama",
      validation_DL_za: "Habang nasa pagitan ng 8 at 13 kasama",
      validation_UID_in: "Numerikal. Habang 12",
      validation_PASS_ph:
          "Mangyaring ilagay ang 9 na karakter ng iyong numero ng Pasaporte.",
      validation_PASS_la:
          "Alphanumerikal. Isang karakter na sinusundan ng 7 digit",
      validation_PASS_jp: "Habang nasa pagitan ng 9 at 12 kasama",
      validation_PASS_ng: "Habang nasa pagitan ng 9 at 12 kasama",
      validation_PASS_gh: "Habang nasa pagitan ng 9 at 12 kasama",
      validation_PASS_za: "Habang nasa pagitan ng 9 at 12 kasama",
      validation_RD_jp: "Habang nasa pagitan ng 9 at 12 kasama",
      validation_PCN_ph:
          "Mangyaring ilagay ang 16 na karakter ng iyong numero ng PCN.",
      validation_CRN_ph:
          "Mangyaring ilagay ang 12 karakter ng iyong numero ng CRN.",
    },

    adminRejectTopic: "Humihingi kami ng paumanhin sa naibigay na abala.",
    adminRejectReason:
        "Humihingi kami ng paumanhin sa naibigay na abala. May natuklasang isyu sa ibinigay na impormasyon, na nangangailangan ng muling pagsusuri. Mangyaring isumite muli ang inyong patunay ng pagkakakilanlan para sa karagdagang pagsusuri.",
    bookbankverifyTitle: "Patunay ng pag -verify sa bangko",
    kycTitle: "	Patunay ng pagpapatunay ng pagkakakilanlan	",
    step1: "	I -verify ang dokumento	",
    step2: "	Impormasyon sa Base	",
    step3: "	I -verify ang tagumpay	",
    continue: "	Magpatuloy	",
    verifyidentity: "	Patunayan ang iyong pagkakakilanlan	",
    acceptedfile:
        "	Ang mga tinanggap na uri ng file ay ang JPG, JPEG, PNG at MAX na laki ng file ay 8 MB.	",
    cameradevice: "	Aparato ng camera	",
    uploadfile: "	Mag -upload ng file	",
    or: "	Ibang paraan	",
    anotherdevice: "	Gumamit ng isa pang aparato	",
    anotherdeviceDetail:
        "	Ang iyong personal na impormasyon ay ligtas. Ang data na nakuha mula sa iyong dokumento ng ID ay mananatili sa loob ng mga IUX at hindi kailanman maibabahagi sa ibang mga kumpanya.	",
    scancode: "	I -scan ang code sa ibaba upang gumamit ng isa pang aparato.	",
    back: "	Balik	",
    verificationFailed: "	Nabigo ang pag-verify	",
    sorryverify: "	Paumanhin! Hindi namin ma -verify ang iyong pagkakakilanlan	",
    needsupport:
        "Kailangan ng tulong? Narito kami upang matulungan ka 24/7 \n mangyaring makipag -ugnay sa amin sa pamamagitan ng live chat.",
    uploadAgian: "	Mag -upload ulit	",
    tempblock: "	Ang pagtatangka sa pag -verify ay pansamantalang naharang	",
    tempblockdetail:
        "	Ang pagtatangka ng pag -verify ng pagkakakilanlan ay pansamantalang naharang sa loob ng 5 minuto. Mabuting subukang mag -upload ng isa pang dokumento sa ibang pagkakataon.	",
    permanently: "	Ang pagtatangka ng pag -verify ay permanenteng naharang	",
    permanentlydetail:
        "	Ang iyong pagtatangka sa pagpapatunay ng pagkakakilanlan ay permanenteng naharang. Mangyaring makipag -ugnay sa suporta sa IUX sa pamamagitan ng live chat para sa karagdagang tulong.	",
    countdown: "	Countdown	",
    loadingtitle:
        "	Habang ang pagproseso na ito, mangyaring panatilihing bukas ang pahinang ito	",
    autoverify: "Awto-matikong veripikasyon",
    tutorial: "Tutorial",
    manualverify: "	Manu -manong Pag -verify ng Pagkakakilanlan	",
    trydemo: "	Subukan ang demo	",
    verifySuccess: "	Tagumpay sa pag -verify	",
    verifySuccessdetial: "	Ang iyong pagkakakilanlan ay na -verify.	",
    manualtitle: "	Dokumento ng pagkakakilanlan	",
    manualsubtitle: "Mangyaring mag -upload ng larawan ng	",
    manualsubtitle2:
        "Ang larawan ay dapat na maliwanag at malinaw, at ang lahat ng mga koneksyon ng iyong dokumento ay dapat makita.",
    fontcard: "	Harapan	",
    backcard: "	Balik	",
    manualselfie: "	I -upload ang selfie na may patunay	",
    manualselfiedetail:
        "	Mangyaring mag -upload ng isang selfie kasama ang iyong dokumento ng Aadhaar at ang website ng IUX na binuksan sa browser ng Internet sa parehong larawan. Tiyakin na ang parehong ay malinaw na nakikita at na ang iyong mukha ay hindi sakop.	",
    otpverifytitle: "	Pag -verify ng OTP	",
    invalidphone:
        "	Di -wastong telepono, mabait na i -edit ang numero ng iyong telepono.	",
    requestotp: "	Humiling ng OTP	",
    enterotp: "	Ipasok ang code mula sa SMS na ipinadala namin	",
    codeexpiresin: "	Mag -expire ang code sa	",
    seconds: "	Segundo	",
    codenotmatch: "	Hindi tumutugma ang code.	",
    bookbankuploadtitle:
        "Mangyaring kumuha ng litrato ng bank passbook mo at siguruhing ang iyong mga dokumento ay sumusunod sa mga sumusunod na kinakailangan",
    bookbankuploaddetail1: "	Magandang kalidad, maliwanag at malinaw.	",
    bookbankuploaddetail2: "	Uri ng File JPG, JPEG, PNG, PDF.	",
    bookbankuploaddetail3:
        "	Ang lahat ng mga sulok ng iyong dokumento ay dapat makita.	",
    bookbankfilesize: "	Ang laki ng file ay hindi maaaring lumampas sa 8MB.	",
    didotp: "Hindi ba natanggap ang OTP?",
    error_verify_type_file: "Paumanhin, hindi matatanggap ang uri ng file.",
    error_verify_kyc:
        "Hindi matagumpay, hindi namin nakuha ang impormasyon mula sa imahe.",
    inprogress: "Sa pag -unlad",
    sendverifysuccess:
        "Matagumpay na isinumite! Karaniwang tumatagal lamang ng 3-15 minuto para makumpleto namin ang mga kinakailangang tseke at kumpirmahin ang iyong dokumento.",
    kyc_scanning: "	Pag -scan ...	",
    kyc_detected: "	Matagumpay ang pagtuklas	",
    kyc_angle: "	Ayusin ang anggulo	",
    kyc_fail: "Nabigo ang pagtuklas",
    kyc_move_far: "Ilipat ang dokumento nang mas malayo",
    kyc_move: "	Ilipat ang dokumento nang mas malapit	",
  },
  ja: {
    something: "何か問題が発生しました",
    tryagain: "もう一度お試しください",
    documentType: {
      ID: "身分証明書",
      DL: "運転免許証",
      UID: "ユニーク識別番号（アーダール）",
      PASS: "パスポート",
      RD: "居住カード",
      PCN: "フィリピン国民IDカード（PhilID）",
      CRN: "統合多目的ID",
      validation_ID_th: "ID番号の10〜14文字を入力してください。",
      validation_ID_my: "MyKad番号を10から14文字以内で入力してください。",
      validation_ID_la: "ID番号を9から14文字以内で入力してください。",
      validation_ID_ng: "ID番号を9から14文字以内で入力してください。",
      validation_ID_gh: "ID番号を9から17文字以内で入力してください。",
      validation_ID_za: "ID番号を9から14文字以内で入力してください。",
      validation_ID_id: "NIK番号の14〜18文字を入力してください。",
      validation_NIK_id: "NIK番号の14〜18文字を入力してください。",
      validation_ID_vn:
          "ID番号の9〜13文字を入力してください。古いカード形式は受け付けられません。",
      validation_ID_in: "8〜12文字の長さ",
      validation_ID_jp: "9〜12文字の長さ",
      validation_DL_my: "運転免許番号の10〜14文字を入力してください。",
      validation_DL_ph: "運転免許番号の11文字を入力してください。",
      validation_DL_in: "15〜16文字の長さ",
      validation_DL_jp: "9〜12文字の長さ",
      validation_DL_ng: "8〜13文字の長さ",
      validation_DL_gh: "8〜16文字の長さ",
      validation_DL_za: "8〜13文字の長さ",
      validation_UID_in: "数字。長さ12",
      validation_PASS_ph: "パスポート番号の9文字を入力してください。",
      validation_PASS_la: "英数字。1文字に続く7桁の数字",
      validation_PASS_jp: "9〜12文字の長さ",
      validation_PASS_ng: "9〜12文字の長さ",
      validation_PASS_gh: "9〜12文字の長さ",
      validation_PASS_za: "9〜12文字の長さ",
      validation_RD_jp: "9〜12文字の長さ",
      validation_PCN_ph: "PCN番号の16文字を入力してください。",
      validation_CRN_ph: "CRN番号の12文字を入力してください。",
    },

    adminRejectTopic: "発生した不便についてお詫び申し上げます。",
    adminRejectReason:
        "発生した不便についてお詫び申し上げます。提供された情報に問題が判明し、再評価が必要です。詳細な検討のために身分証明書を再提出してください。",
    bookbankverifyTitle: "銀行検証の証明",
    kycTitle: "アイデンティティ検証の証明",
    step1: "ドキュメントを確認します",
    step2: "ベース\n報",
    step3: "成功を確認します",
    continue: "続く",
    verifyidentity: "あなたの身元を確認します",
    acceptedfile:
        "受け入れられたファイルタイプは、JPG、JPEG、PNG、および最大ファイルサイズは8 MBです。",
    cameradevice: "カメラデバイス",
    uploadfile: "ファイルをアップロードする",
    or: "または",
    anotherdevice: "別のデバイスを使用します",
    anotherdeviceDetail:
        "あなたの個人情報は安全です。 IDドキュメントから抽出されたデータは、IUX市場内にとどまり、他社と共有されることはありません。",
    scancode: "以下のコードをスキャンして、別のデバイスを使用します。",
    back: "戻る",
    verificationFailed: "検証に失敗しました",
    sorryverify: "ごめん！ あなたの身元を確認できませんでした",
    needsupport:
        "手伝いが必要？ 24時間年中無休でお手伝いします。 nライブチャットでお問い合わせください。",
    uploadAgian: "もう一度アップロードします",
    tempblock: "検証の試みは一時的にブロックされました",
    tempblockdetail:
        "ID検証の試みは、5分間一時的にブロックされています。 後で別のドキュメントをアップロードしてみてください。",
    permanently: "検証の試みは永久にブロックされました",
    permanentlydetail:
        "あなたの身元確認の試みは永久にブロックされています。 ライブチャットを通じてIUX Supportに連絡してください。",
    countdown: "秒読み",
    loadingtitle: "この処理中は、このページを開いたままにしてください",
    autoverify: "自動検証",
    tutorial: "チュートリアル",
    manualverify: "手動IDの検証",
    trydemo: "デモを試してみてください",
    verifySuccess: "検証の成功",
    verifySuccessdetial: "あなたの身元が検証されています。",
    manualtitle: "身分証明書",
    manualsubtitle: "あなたの写真をアップロードしてください",
    manualsubtitle2:
        "写真は明るく明確でなければならず、あなたのドキュメントのすべてのコナーは見える必要があります。",
    fontcard: "フロント",
    backcard: "戻る",
    manualselfie: "自撮りを証明でアップロードします",
    manualselfiedetail:
        "AadhaarドキュメントとIUXのWebサイトを同じ写真にインターネットブラウザにオープンしたセルフィーをアップロードしてください。 両方がはっきりと見え、顔が覆われていないことを確認してください。",
    otpverifytitle: "OTP検証",
    invalidphone: "無効な電話、電話番号を親切に編集してください。",
    requestotp: "OTPをリクエストします",
    enterotp: "送信したSMSからコードを入力します",
    codeexpiresin: "コードはで期限切れになります",
    seconds: "秒",
    codenotmatch: "コードは一致しません。",
    bookbankuploadtitle:
        "あなたの銀行通帳の写真を撮って、あなたの文書が以下の要件を満たしていることを確認してください",
    bookbankuploaddetail1: "良質で、明るく明確です。",
    bookbankuploaddetail2: "ファイルタイプJPG、JPEG、PNG、PDF。",
    bookbankuploaddetail3:
        "ドキュメントのすべてのコーナーが表示されなければなりません。",
    bookbankfilesize: "ファイルサイズは8MBを超えることはできません。",
    didotp: "OTPを受け取りませんでしたか？",
    error_verify_type_file:
        "申し訳ありませんが、ファイルタイプは受け入れられません。",
    error_verify_kyc:
        "失敗して、画像から情報を抽出することができませんでした。",
    inprogress: "進行中",
    sendverifysuccess:
        "正常に提出されました！ 通常、必要なチェックを完了してドキュメントを確認するには、わずか3〜15分かかります。",
    kyc_scanning: "走査...",
    kyc_detected: "検出が成功しました",
    kyc_angle: "角度を調整します",
    kyc_fail: "検出に失敗しました",
    kyc_move_far: "ドキュメントをさらに移動します",
    kyc_move: "ドキュメントを近くに移動します",
  },
  es: {
    something: "Algo salió mal",
    tryagain: "Por favor, inténtalo de nuevo",
    documentType: {
      ID: "Tarjeta de Identificación",
      DL: "Licencia de Conducir",
      UID: "Número de Identificación Único (Aadhaar)",
      PASS: "Pasaporte",
      RD: "Tarjeta de Residencia",
      PCN: "Tarjeta Nacional de Identificación de Filipinas (PhilID)",
      CRN: "ID Multifuncional Unificado",
      validation_ID_th:
          "Por favor, ingresa entre 10 y 14 caracteres de tu número de identificación.",
      validation_ID_my:
          "Por favor, ingrese entre 10 y 14 caracteres de su número MyKad.",
      validation_ID_la:
          "Por favor, ingrese entre 9 y 14 caracteres de su número de identificación.",
      validation_ID_ng:
          "Por favor, ingrese entre 9 y 14 caracteres de su número de identificación.",
      validation_ID_gh:
          "Por favor, ingrese entre 9 y 17 caracteres de su número de identificación.",
      validation_ID_za:
          "Por favor, ingrese entre 9 y 14 caracteres de su número de identificación.",
      validation_ID_id:
          "Por favor, ingresa entre 14 y 18 caracteres de tu número de NIK.",
      validation_NIK_id:
          "Por favor, ingresa entre 14 y 18 caracteres de tu número de NIK.",
      validation_ID_vn:
          "Por favor, ingresa entre 9 y 13 caracteres de tu número de identificación y el formato de la tarjeta antigua no es aceptable.",
      validation_ID_in: "Longitud entre 8 y 12 inclusive",
      validation_ID_jp: "Longitud entre 9 y 12 inclusive",
      validation_DL_my:
          "Por favor, ingresa entre 10 y 14 caracteres de tu número de licencia de conducir.",
      validation_DL_ph:
          "Por favor, ingresa los 11 caracteres de tu número de licencia de conducir.",
      validation_DL_in: "Longitud entre 15 y 16 inclusive",
      validation_DL_jp: "Longitud entre 9 y 12 inclusive",
      validation_DL_ng: "Longitud entre 8 y 13 inclusive",
      validation_DL_gh: "Longitud entre 8 y 16 inclusive",
      validation_DL_za: "Longitud entre 8 y 13 inclusive",
      validation_UID_in: "Numérico. Longitud 12",
      validation_PASS_ph:
          "Por favor, ingresa los 9 caracteres de tu número de pasaporte.",
      validation_PASS_la: "Alfanumérico. Un carácter seguido de 7 dígitos",
      validation_PASS_jp: "Longitud entre 9 y 12 inclusive",
      validation_PASS_ng: "Longitud entre 9 y 12 inclusive",
      validation_PASS_gh: "Longitud entre 9 y 12 inclusive",
      validation_PASS_za: "Longitud entre 9 y 12 inclusive",
      validation_RD_jp: "Longitud entre 9 y 12 inclusive",
      validation_PCN_ph:
          "Por favor, ingresa los 16 caracteres de tu número de PCN.",
      validation_CRN_ph:
          "Por favor, ingresa los 12 caracteres de tu número de CRN.",
    },
    adminRejectTopic: "Nos disculpamos por los inconvenientes causados.",
    adminRejectReason:
        "Nos disculpamos por los inconvenientes causados. Se ha identificado un problema con la información proporcionada, lo que requiere una reevaluación. Por favor, vuelve a enviar tu prueba de identidad para una revisión adicional.",
    bookbankverifyTitle: "Verificación de prueba bancaria",
    kycTitle: "Verificación de prueba de identidad",
    step1: "Verificar documento",
    step2: "Información Base",
    step3: "Verificación exitosa",
    continue: "Continuar",
    verifyidentity: "Verifica tu identidad",
    acceptedfile:
        "Los tipos de archivo aceptados son jpg, jpeg, png y el tamaño máximo de archivo es de 8 MB.",
    cameradevice: "Dispositivo de cámara",
    uploadfile: "Subir archivo",
    or: "o",
    anotherdevice: "Usar otro dispositivo",
    anotherdeviceDetail:
        "Tu información personal está segura. Los datos extraídos de tu documento de identificación permanecen dentro de IUX y nunca se comparten con otras empresas.",
    scancode: "Escanea el código a continuación para usar otro dispositivo.",
    back: "Atrás",
    verificationFailed: "Verificación Fallida",
    sorryverify: "¡Lo sentimos! No pudimos verificar tu identidad",
    needsupport:
        "¿Necesitas ayuda? Estamos aquí para ayudarte 24/7. Por favor, contáctanos a través del Chat en Vivo.",
    uploadAgian: "Subir de Nuevo",
    tempblock: "Intento de verificación temporalmente bloqueado",
    tempblockdetail:
        "El intento de verificación de identidad ha sido bloqueado temporalmente por una duración de 5 minutos. Intenta subir otro documento más tarde.",
    permanently: "Intento de verificación permanentemente bloqueado",
    permanentlydetail:
        "Tu intento de verificación de identidad ha sido bloqueado permanentemente. Por favor, contacta a Soporte de IUX a través del Chat en Vivo para más asistencia.",
    countdown: "Cuenta regresiva",
    loadingtitle: "Mientras se procesa, por favor mantén esta página abierta",
    autoverify: "Verificación automática",
    tutorial: "Tutorial",
    manualverify: "Verificación manual",
    trydemo: "Probar Demo",
    verifySuccess: "Verificación Exitosa",
    verifySuccessdetial: "Tu identidad ha sido verificada.",
    manualtitle: "Documento de identidad",
    manualsubtitle: "Por favor, sube una foto de tu",
    manualsubtitle2:
        "La foto debe ser brillante y clara, y todas las esquinas de tu documento deben ser visibles.",
    fontcard: "Frontal",
    backcard: "Reverso",
    manualselfie: "Sube la selfie con la prueba",
    manualselfiedetail:
        "Por favor, sube una selfie con tu documento de Aadhaar y el sitio web de IUX abierto en el navegador de internet en la misma foto. Asegúrate de que ambos sean claramente visibles y que tu cara no esté cubierta.",
    otpverifytitle: "Verificación OTP",
    invalidphone: "Teléfono inválido, por favor edita tu número de teléfono.",
    requestotp: "Solicitar OTP",
    enterotp: "Ingresa el código del SMS que enviamos a",
    codeexpiresin: "El código expira en",
    seconds: "segundos",
    codenotmatch: "El código no coincide.",
    bookbankuploadtitle:
        "Por favor, toma una foto de tu libreta bancaria y asegúrate de que tus documentos cumplan con los siguientes requisitos.",
    bookbankuploaddetail1: "Buena calidad, brillante y clara.",
    bookbankuploaddetail2: "Tipo de archivo jpg, jpeg, png, pdf.",
    bookbankuploaddetail3:
        "Todas las esquinas de tu documento deben ser visibles.",
    bookbankfilesize: "El tamaño del archivo no puede exceder los 8mb.",
    didotp: "¿No recibiste OTP?",
    error_verify_type_file:
        "Lo sentimos, el tipo de archivo no puede ser aceptado.",
    error_verify_kyc:
        "No exitoso, no pudimos extraer información de la imagen.",
    inprogress: "En progreso",
    sendverifysuccess:
        "¡Enviado con éxito! Normalmente nos toma entre 3 y 15 minutos completar las verificaciones necesarias y confirmar tu documento.",
    kyc_scanning: "Escaneando...",
    kyc_detected: "Detección exitosa",
    kyc_angle: "Ajustar el ángulo",
    kyc_fail: "Detección fallida",
    kyc_move_far: "Mover el documento más lejos",
    kyc_move: "Mover el documento más cerca",
  },
};

