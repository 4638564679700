import React, {useEffect, useState} from "react";
import Axios from "axios";
import {useSelector} from "react-redux";
import useLocationStorage from "../../helper/useLocationStorage";

const locale = require("react-redux-i18n").I18n;
const buttonStyle = {
  color: "black",
};

const FooterAuthPage = (props) => {
  const [isCountry, setIsCountry] = useState("th");

  const fetchcountry = async () => {
    try {
      const response = await Axios.get(`${process.env.REACT_APP_DOMAIN}`);
      const langIndex = response.data.indexOf('lang="');
      const langSubstring = response.data.substring(
        langIndex + 'lang="'.length
      );
      const langValue = langSubstring.substring(0, langSubstring.indexOf('"'));
      setIsCountry(langValue);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchcountry();
  }, []);

  const [location] = useLocationStorage();

  return (
    <div
      className={
        props.dark ? "text-center bg-lotback pb-4" : "text-center pb-4"
      }
      style={{background: props.isDashboard ? "transparent" : "#2f2f2f"}}
    >
      {!props.isDashboard && (
        <div className="landingPage-flex-block container ">
          <div className="my-4 d-flex justify-content-between w-100 align-items-center">
            <a href="http://iux.com/">
              <img
                width={48}
                height={48}
                alt="IUX"
                className="registerlogo border-logo"
                src={
                  process.env.PUBLIC_URL + "/assets/images/icons/iux-logo.png"
                }
              />{" "}
              <span className="fs-24 text-white font-bold-iux ml-2"> IUX</span>
            </a>
            <div className="d-flex">
              {" "}
              <div className=" mr-2">
                <a href="https://www.facebook.com/iuxglobal/" target="blank">
                  <img
                    width={24}
                    className="w-[24px] md:w-[48px]"
                    src={"/assets/images/landing/social/facebook.svg"}
                    alt="facebook"
                  />
                </a>
              </div>
              <div className=" mr-2">
                <a
                  href="https://www.instagram.com/iux_official/"
                  target="blank"
                >
                  <img
                    width={24}
                    className="w-[24px] md:w-[48px]"
                    src={"/assets/images/landing/social/ig.svg"}
                    alt="instagram"
                  />
                </a>
              </div>
              <div className=" mr-2">
                <a href="https://x.com/iux_official" target="blank">
                  <img
                    width={24}
                    className="w-[24px] md:w-[48px]"
                    src={"/assets/images/landing/social/x.svg"}
                    alt="x"
                  />
                </a>
              </div>
              <div className=" mr-2">
                <a href="https://www.youtube.com/@iuxglobal" target="blank">
                  <img
                    width={24}
                    className="w-[24px] md:w-[48px]"
                    src={"/assets/images/landing/social/youtube.svg"}
                    alt="iuxmarket"
                  />
                </a>
              </div>
              <div className=" mr-2">
                <a
                  href="https://www.linkedin.com/company/iux-market/"
                  target="blank"
                >
                  <img
                    width={24}
                    className="w-[24px] md:w-[48px]"
                    src={"/assets/images/landing/social/linkedin.svg"}
                    alt="linkedin"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        style={{
          paddingBottom: 16,
          textAlign: "left",
          whiteSpace: "break-spaces",
          overflow: "hidden",
        }}
        className={`container ${
          props.isDashboard ? "text-black" : "text-white"
        } fs-12`}
      >
        {location === 'ZA' ? (
          <>
            <b>Financial Services Provider (FSP) details</b>
            <p>
              IUX Markets ZA (Pty) Ltd. authorised and regulated by Financial Sector Conduct Authority (FSCA) in South
              Africa with FSP Number 53103
            </p>
            <p>
              Registered address: 1st Floor Cnr Kildare Road and MA, Newlands, Cape Town, Western Cape, 7550 SOUTH
              AFRICA
            </p>
            <br/>
            <p>
              This website is operated by The FSP. IUX Markets ZA is a product of IUX Markets (ZA) Ltd. This FSP is
              not the market maker, or product issuer, and acts solely as an intermediary in terms of the FAIS Act
              between the client and IUX Markets (MU) Ltd. (the Product Supplier), rendering only an intermediary
              service (i.e., no market making is conducted by IUX Markets ZA (Pty) Ltd. in relation to derivative
              products offered by IUX Markets (MU) Ltd. Therefore, IUX Markets ZA (Pty) Ltd does not act as the
              principal or the counterparty in any of its transactions.
            </p>
            <br/>
            <p>
              By proceeding with opening an account, this will be registered with IUX Markets (MU) Ltd., which is
              authorized and regulated by the Financial Services Commission (FSC) in Mauritius. IUX Markets (MU) Ltd.
              falls outside the South African regulatory framework and Mauritius laws and regulations will apply in
              relation to your account.
            </p>
            <br/>
            <b>Product Supplier details</b>
            <p>
              IUX Markets (MU) Ltd. authorised and regulated by Financial Services Commission (FSC) in Mauritius with
              Investment Dealer license number GB22200605
            </p>
            <p>
              Registered address: Hotel Avenue C/o JurisTax Ltd, Ebene House 33 Cybercity, Ebene, 72201 MAURITIUS
            </p>
            <br/>
            <b>
              Legal: This website is operated by IUX Markets ZA (Pty) Ltd. authorised and regulated by Financial
              Sector Conduct Authority (FSCA) in South Africa with FSP Number 53103
            </b>
            <p>
              Risk Warning: Please note that CFDs trading and other credit products trading is high risk and is not
              suitable for all investors. Trading financial instruments may result in losses as well. To be profitable
              And losses that can exceed the initial investment. Before the transaction, be sure to understand all the
              risks and should receive unbiased advice if necessary. Under no circumstances shall IUX Markets have any
              liability to any person or entity for any loss or damage in whole or part caused by, resulting from, or
              relating to any investing activity. This information is not intended for residents of countries or
              jurisdictions under significant sanctions, such as: Afghanistan, Barbados, Belarus, Burkina Faso,
              Cameroon, Central African Republic, Cuba, Democratic Republic of Congo, Haiti, Iran, Libya, Mali,
              Mozambique, Myanmar, Nicaragua, North Korea, Russia, Senegal, Sudan, Syria, Tanzania, Venezuela, Yemen,
              and Zimbabwe.
            </p>
            <br/>
            <p>
              All trading related information on this website is not intended to solicit residents of Australia,
              Belgium, Canada, France, Japan, Ukraine, United Kingdom, and United States.
            </p>
            <br/>
            <p>
              Please note that leveraged margin trading and binary options carry significant risks, including the
              potential for rapid financial losses.
            </p>
          </>
        ) : (
          <>
            <div>
              {props.locale.t(`footer_FSC`)}
            </div>
            <br/>
            {props.locale.t(`footer_title_2_1`)} &nbsp;
            <a
              href="/assets/IML-COE-ID.pdf"
              target="blank"
              className="text-primary-new-iux"
            >
              26183 BC 2021
            </a>{" "}
            {props.locale.t(`footer_title_2_2`)}
            <br/>
            <br/>
            {locale.t(`footer_FSCA_text_1`)}
            &nbsp;
            <a
              href="https://www.fsca.co.za/Fais/Search_FSP.htm"
              target="_blank"
              className="text-primary-new-iux"
            >
              53103&nbsp;
            </a>
            {locale.t(`footer_FSCA_text_2`)}
            <br/>
            <div style={{height: 20}}></div>
            {isCountry !== "th" && isCountry !== "la" && (
              <div id="footer-warning-address">
                {props.locale.t("website_warning_address")}
                <br/>
              </div>
            )}
            <div id="footer-warning">{props.locale.t("website_warning")}</div>
          </>
        )
        }
        <br/>
        ©️ 2024 IUX Markets Limited.
        {/* {props.locale.t("copyright")} */}
      </div>
    </div>
  );
};

export default FooterAuthPage;
