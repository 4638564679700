import React, { useEffect } from "react";
import "./assets/scss/app.scss";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

import {
  loadTranslations,
  setLocale,
  syncTranslationWithStore,
  i18nReducer,
} from "react-redux-i18n";
import HttpsRedirect from "react-https-redirect";
import { translationsObject } from "./transations";
import "./App.css";
import "./index.css";
import "./Card.css";
import "./Loyalty.css";

// import "antd/dist/antd.css";
import "react-alice-carousel/lib/alice-carousel.css";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import Router from "./Router";
import reducers from "./reducers";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  combineReducers({
    ...reducers,
    i18n: i18nReducer,
  }),
  composeEnhancers(applyMiddleware(thunk))
);

syncTranslationWithStore(store);
store.dispatch(loadTranslations(translationsObject));
store.dispatch(setLocale(localStorage.getItem("locale") || "th"));

// class App extends Component {
//   componentWillMount() {
//     if (!store.getState().auth.isInitialize) {
//       store.dispatch(YongAuth(store.getState().user));
//       if (localStorage.getItem("locale") === "la") {
//         document.body.style.cssText = "font-family: Noto Sans Lao !important;";
//       }
//     }
//   }

//   render() {
//     return (
//       <>
//         <Provider store={store}>
//           <HttpsRedirect>
//             <Router />
//           </HttpsRedirect>
//         </Provider>
//       </>
//     );
//   }
// }

// export default App;

function App() {
  useEffect(() => {
    InitialFirebase();
    if (!store.getState().auth.isInitialize) {
      // store.dispatch(YongAuth(store.getState().user));
      if (localStorage.getItem("locale") === "lo") {
        document.body.style.cssText = "font-family: Noto Sans Lao !important;";
      }
    }
  }, []);
  const InitialFirebase = async () => {
    try {
      // getuser();
    } catch (error) {}
  };
  return (
    <>
      <Provider store={store}>
        <HttpsRedirect>
          <Router />
        </HttpsRedirect>
      </Provider>
    </>
  );
}

export default App;
