export const CheckLanguage = (id) => {
  switch (id) {
    case "th":
      return 1;
    case "en":
      return 2;
    case "zh":
      return 4;
    case "ms":
      return 6;
    case "hi":
      return 7;
    case "lo":
      return 8;
    case "id":
      return 9;
    case "vi":
      return 10;
    case "fil":
      return 12;
    case "es":
      return 16;
    case "za":
      return 27;
    default:
      return 2;
  }
};
//
