export const cfds = [
  "AUDUSD",
  "CADJPY",
  "EURGBP",
  "EURJPY",
  "EURUSD",
  "GBPUSD",
  "NZDUSD",
  "USDCAD",
  "USDCHF",
  "USDJPY",
  "AUDCAD",
  "AUDCHF",
  "AUDJPY",
  "AUDNZD",
  "CADCHF",
  "CHFJPY",
  "CHFSGD",
  "ERUAUD",
  "EURCAD",
  "EURCHF",
  "EURDKK",
  "EURHKD",
  "EURHUF",
  "EURMXN",
  "EURNOK",
  "EURNZD",
  "EURPLN",
  "EURSEK",
  "EURTRY",
  "ERUZAR",
  "GBPAUD",
  "GBPCAD",
  "GBPCHF",
  "GBPDKK",
  "GBPJPY",
  "GBPNOK",
  "GBPNZD",
  "GBPSEK",
  "GBPTRY",
  "NZDCAD",
  "NZDCHF",
  "NZDJPY",
  "NZDSGD",
  "USDCNH",
  "USDZCK",
  "USDDKK",
  "USDHKD",
  "USDHUF",
  "USDMXN",
  "USDNOK",
  "USDPLN",
  "USDRUB",
  "USDSEK",
  "USDSGD",
  "USDTRY",
  "USDZAR",
  "UKOUSD",
  "USOUSD",
  "XNGUSD",
  "XPDUSD",
  "XPTUSD",
  "EURAUD",
  "USDCNY",
  "EURSGD",
  "USDTHB",
  "GBPSGD",
  "CADSGD",
  "AUDSGD",
  "SGDJPY",
];
export const cryptoCurrency = [
  "BTCEUR",
  "BTCGBP",
  "BTCUSD",
  "DSHUSD",
  "ETHEUR",
  "ETHGBP",
  "ETHUSD",
  "LTCUSD",
  "XRPUSD",
  "DOTUSD",
  "BCHUSD",
  "BNBUSD",
  "ADAUSD",
  "UNIUSD",
  "XTZUSD",
  "SOLUSD",
  "BATUSD",
  "TRXUSD",
  "XMRUSD",
  "ATMUSD",
  "AVAUSD",
];

export const indexs = [
  "100GBP",
  "AUS200",
  "D30EUR",
  "DJI30",
  "DXYUSD",
  "E35EUR",
  "E50EUR",
  "F40EUR",
  "JPN225",
  "NASUSD",
  "SP500",
  "HK50",
  "DE30",
  "US30",
  "S&P500",
  "UK100",
  "DXY",
  "FR40",
  "STOXX50",
  "USTEC",
];
export const datacommodities = [
  "XAUUSD",
  "USOIL",
  "XAGEUR",
  "XAGUSD",
  "XAUEUR",
  "UKOIL",
];

export const marketStock = [
  "AAPL",
  "ADBE",
  "ALIBABA",
  "AMZN",
  "AMD",
  "BA",
  "BABA",
  "BAC",
  "CHTR",
  "CMCSA",
  "CME",
  "COST",
  "CSCO",
  "CSX",
  "CVS",
  "Citi",
  "EA",
  "EBAY",
  "EQIX",
  "Ford",
  "GILD",
  "GOOGL",
  "HD",
  "IBM",
  "INTC",
  "ISRG",
  "JNJ",
  "JPM",
  "KO",
  "LIN",
  "LLY",
  "LMT",
  "MA",
  "MCD",
  "MDLZ",
  "MMM",
  "MRK",
  "MS",
  "MSFT",
  "NFLX",
  "NKE",
  "NVDA",
  "ORCL",
  "PEP",
  "PFE",
  "PG",
  "PYPL",
  "SBUX",
  "TMO",
  "TMUS",
  "TSLA",
  "UNH",
  "VRTX",
  "Visa",
  "WMT",
  "XOM",
  "META",
];
export const cfdsData = [
  {
    name: "AUDUSD",
    display: "AUDUSD",
  },
  {
    name: "CADJPY",
    display: "CADJPY",
  },
  {
    name: "EURGBP",
    display: "EURGBP",
  },
  {
    name: "EURJPY",
    display: "EURJPY",
  },
  {
    name: "EURUSD",
    display: "EURUSD",
  },
  {
    name: "GBPUSD",
    display: "GBPUSD",
  },
  {
    name: "NZDUSD",
    display: "NZDUSD",
  },
  {
    name: "USDCAD",
    display: "USDCAD",
  },
  {
    name: "USDCHF",
    display: "USDCHF",
  },
  {
    name: "USDJPY",
    display: "USDJPY",
  },
  {
    name: "AUDCAD",
    display: "AUDCAD",
  },
  {
    name: "AUDCHF",
    display: "AUDCHF",
  },
  {
    name: "AUDJPY",
    display: "AUDJPY",
  },
  {
    name: "AUDNZD",
    display: "AUDNZD",
  },
  {
    name: "CADCHF",
    display: "CADCHF",
  },
  {
    name: "CHFJPY",
    display: "CHFJPY",
  },
  {
    name: "CHFSGD",
    display: "CHFSGD",
  },
  {
    name: "ERUAUD",
    display: "ERUAUD",
  },
  {
    name: "EURCAD",
    display: "EURCAD",
  },
  {
    name: "EURCHF",
    display: "EURCHF",
  },
  {
    name: "EURDKK",
    display: "EURDKK",
  },
  {
    name: "EURHKD",
    display: "EURHKD",
  },
  {
    name: "EURHUF",
    display: "EURHUF",
  },
  {
    name: "EURMXN",
    display: "EURMXN",
  },
  {
    name: "EURNOK",
    display: "EURNOK",
  },
  {
    name: "EURNZD",
    display: "EURNZD",
  },
  {
    name: "EURPLN",
    display: "EURPLN",
  },
  {
    name: "EURSEK",
    display: "EURSEK",
  },
  {
    name: "EURTRY",
    display: "EURTRY",
  },
  {
    name: "ERUZAR",
    display: "ERUZAR",
  },
  {
    name: "GBPAUD",
    display: "GBPAUD",
  },
  {
    name: "GBPCAD",
    display: "GBPCAD",
  },
  {
    name: "GBPCHF",
    display: "GBPCHF",
  },
  {
    name: "GBPDKK",
    display: "GBPDKK",
  },
  {
    name: "GBPJPY",
    display: "GBPJPY",
  },
  {
    name: "GBPNOK",
    display: "GBPNOK",
  },
  {
    name: "GBPNZD",
    display: "GBPNZD",
  },
  {
    name: "GBPSEK",
    display: "GBPSEK",
  },
  {
    name: "GBPTRY",
    display: "GBPTRY",
  },
  {
    name: "NZDCAD",
    display: "NZDCAD",
  },
  {
    name: "NZDCHF",
    display: "NZDCHF",
  },
  {
    name: "NZDJPY",
    display: "NZDJPY",
  },
  {
    name: "NZDSGD",
    display: "NZDSGD",
  },
  {
    name: "USDCNH",
    display: "USDCNH",
  },
  {
    name: "USDZCK",
    display: "USDZCK",
  },
  {
    name: "USDDKK",
    display: "USDDKK",
  },
  {
    name: "USDHKD",
    display: "USDHKD",
  },
  {
    name: "USDHUF",
    display: "USDHUF",
  },
  {
    name: "USDMXN",
    display: "USDMXN",
  },
  {
    name: "USDNOK",
    display: "USDNOK",
  },
  {
    name: "USDPLN",
    display: "USDPLN",
  },
  {
    name: "USDRUB",
    display: "USDRUB",
  },
  {
    name: "USDSEK",
    display: "USDSEK",
  },
  {
    name: "USDSGD",
    display: "USDSGD",
  },
  {
    name: "USDTRY",
    display: "USDTRY",
  },
  {
    name: "USDZAR",
    display: "USDZAR",
  },
  {
    name: "UKOUSD",
    display: "UKOUSD",
  },
  {
    name: "USOUSD",
    display: "USOUSD",
  },
  {
    name: "XNGUSD",
    display: "XNGUSD",
  },
  {
    name: "XPDUSD",
    display: "XPDUSD",
  },
  {
    name: "XPTUSD",
    display: "XPTUSD",
  },
  {
    name: "EURAUD",
    display: "EURAUD",
  },
  {
    name: "USDCNY",
    display: "USDCNY",
  },
  {
    name: "EURSGD",
    display: "EURSGD",
  },
  {
    name: "USDTHB",
    display: "USDTHB",
  },
  {
    name: "GBPSGD",
    display: "GBPSGD",
  },
  {
    name: "CADSGD",
    display: "CADSGD",
  },
  {
    name: "AUDSGD",
    display: "AUDSGD",
  },
  {
    name: "SGDJPY",
    display: "SGDJPY",
  },
];
export const cryptoData = [
  {
    name: "BTCEUR",
    display: "BTCEUR",
  },
  {
    name: "BTCGBP",
    display: "BTCGBP",
  },
  {
    name: "BTCUSD",
    display: "BTCUSD",
  },
  {
    name: "DSHUSD",
    display: "DSHUSD",
  },
  {
    name: "ETHEUR",
    display: "ETHEUR",
  },
  {
    name: "ETHGBP",
    display: "ETHGBP",
  },
  {
    name: "ETHUSD",
    display: "ETHUSD",
  },
  {
    name: "LTCUSD",
    display: "LTCUSD",
  },
  {
    name: "XRPUSD",
    display: "XRPUSD",
  },
  {
    name: "DOTUSD",
    display: "DOTUSD",
  },
  {
    name: "BCHUSD",
    display: "BCHUSD",
  },
  {
    name: "BNBUSD",
    display: "BNBUSD",
  },
  {
    name: "XMRUSD",
    display: "XMRUSD",
  },
  {
    name: "ADAUSD",
    display: "ADAUSD",
  },
  {
    name: "UNIUSD",
    display: "UNIUSD",
  },
  {
    name: "XTZUSD",
    display: "XTZUSD",
  },
  {
    name: "SOLUSD",
    display: "SOLUSD",
  },
  {
    name: "BATUSD",
    display: "BATUSD",
  },
  {
    name: "TRXUSD",
    display: "TRXUSD",
  },
  {
    name: "ATMUSD",
    display: "ATMUSD",
  },
  {
    name: "AVAUSD",
    display: "AVAUSD",
  },
];

export const indexData = [
  {
    name: "100GBP",
    display: "100GBP",
  },
  {
    name: "AUS200",
    display: "AUS200",
  },
  {
    name: "D30EUR",
    display: "D30EUR",
  },
  {
    name: "DJI30",
    display: "DJI30",
  },
  {
    name: "DXYUSD",
    display: "DXYUSD",
  },
  {
    name: "E35EUR",
    display: "E35EUR",
  },
  {
    name: "E50EUR",
    display: "E50EUR",
  },
  {
    name: "F40EUR",
    display: "F40EUR",
  },
  {
    name: "JPN225",
    display: "JPN225",
  },
  {
    name: "NASUSD",
    display: "NASUSD",
  },
  {
    name: "SP500",
    display: "SP500",
  },

  {
    name: "HK50",
    display: "HK50",
  },
  {
    name: "DE30",
    display: "DE30",
  },
  {
    name: "US30",
    display: "US30",
  },
  {
    name: "S&P500",
    display: "SP500",
  },
  {
    name: "UK100",
    display: "UK100",
  },
  {
    name: "DXY",
    display: "DXY",
  },
  {
    name: "FR40",
    display: "FR40",
  },
  {
    name: "STOXX50",
    display: "STOXX50",
  },
  {
    name: "USTEC",
    display: "USTEC",
  },
];
export const stockData = [
  {
    name: "AAPL",
    display: "AAPL",
  },
  {
    name: "ADBE",
    display: "ADBE",
  },
  {
    name: "ALIBABA",
    display: "ALIBABA",
  },
  {
    name: "AMZN",
    display: "AMZN",
  },
  {
    name: "AMD",
    display: "AMD",
  },
  {
    name: "BA",
    display: "BA",
  },
  {
    name: "BABA",
    display: "BABA",
  },
  {
    name: "BAC",
    display: "BAC",
  },
  {
    name: "CHTR",
    display: "CHTR",
  },
  {
    name: "CMCSA",
    display: "CMCSA",
  },
  {
    name: "CME",
    display: "CME",
  },
  {
    name: "COST",
    display: "COST",
  },
  {
    name: "CSCO",
    display: "CSCO",
  },
  {
    name: "CSX",
    display: "CSX",
  },
  {
    name: "CVS",
    display: "CVS",
  },
  {
    name: "Citi",
    display: "Citi",
  },
  {
    name: "EA",
    display: "EA",
  },
  {
    name: "EBAY",
    display: "EBAY",
  },
  {
    name: "EQIX",
    display: "EQIX",
  },
  {
    name: "Ford",
    display: "Ford",
  },
  {
    name: "GILD",
    display: "GILD",
  },
  {
    name: "GOOGL",
    display: "GOOGL",
  },
  {
    name: "HD",
    display: "HD",
  },
  {
    name: "IBM",
    display: "IBM",
  },
  {
    name: "INTC",
    display: "INTC",
  },
  {
    name: "ISRG",
    display: "ISRG",
  },
  {
    name: "JNJ",
    display: "JNJ",
  },
  {
    name: "JPM",
    display: "JPM",
  },
  {
    name: "KO",
    display: "KO",
  },
  {
    name: "LIN",
    display: "LIN",
  },
  {
    name: "LLY",
    display: "LLY",
  },
  {
    name: "LMT",
    display: "LMT",
  },
  {
    name: "MA",
    display: "MA",
  },
  {
    name: "MCD",
    display: "MCD",
  },
  {
    name: "MDLZ",
    display: "MDLZ",
  },
  {
    name: "MMM",
    display: "MMM",
  },
  {
    name: "MRK",
    display: "MRK",
  },
  {
    name: "MS",
    display: "MS",
  },
  {
    name: "MSFT",
    display: "MSFT",
  },
  {
    name: "NFLX",
    display: "NFLX",
  },
  {
    name: "NKE",
    display: "NKE",
  },
  {
    name: "NVDA",
    display: "NVDA",
  },
  {
    name: "ORCL",
    display: "ORCL",
  },
  {
    name: "PEP",
    display: "PEP",
  },
  {
    name: "PFE",
    display: "PFE",
  },
  {
    name: "PG",
    display: "PG",
  },
  {
    name: "PYPL",
    display: "PYPL",
  },
  {
    name: "SBUX",
    display: "SBUX",
  },
  {
    name: "TMO",
    display: "TMO",
  },
  {
    name: "TMUS",
    display: "TMUS",
  },
  {
    name: "TSLA",
    display: "TSLA",
  },
  {
    name: "UNH",
    display: "UNH",
  },
  {
    name: "VRTX",
    display: "VRTX",
  },
  {
    name: "Visa",
    display: "Visa",
  },
  {
    name: "WMT",
    display: "WMT",
  },
  {
    name: "XOM",
    display: "XOM",
  },
  {
    name: "META",
    display: "META",
  },
];
export const commoditiesData = [
  {
    name: "XAUUSD",
    display: "XAUUSD",
  },
  {
    name: "USOIL",
    display: "USOIL",
  },
  {
    name: "XAGEUR",
    display: "XAGEUR",
  },
  {
    name: "XAGUSD",
    display: "XAGUSD",
  },
  {
    name: "XAUEUR",
    display: "XAUEUR",
  },
  {
    name: "UKOIL",
    display: "UKOIL",
  },
];
